
import React from 'react';
import axios from 'axios';
const nodeEnv = process.env.NODE_ENV;
export const baseUrl =
  nodeEnv === "production"
    ? "https://sds-2a76.onrender.com"
    : "http://localhost:4000";
const http = axios.create({
  baseUrl
});

http.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default function Provider(props) {
  return <Provider client={http} {...props} />;
}



















