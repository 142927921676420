// import React, { useState } from "react";
// import { Row, Col, Form, Button } from "react-bootstrap";
// import { gql, useMutation } from "@apollo/client";
// import { Link } from "react-router-dom";

// // here we are defining our register mutation. We will pass this to our usemutation hook to send mutations to
// // out server

// const REGISTER_USER = gql`
//   mutation register(
//     $username: String!
//     $email: String!
//     $password: String!
//     $confirmPassword: String!
//   ) {
//     register(
//       username: $username
//       email: $email
//       password: $password
//       confirmPassword: $confirmPassword
//     ) {
//       username
//       email
//       createdAt
//     }
//   }
// `;

// export default function Register(props) {
//   const [variables, setVariables] = useState({
//     email: "",
//     username: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const [errors, setErrors] = useState({});

//   // the useMutation hook takes a second parameter which is an object containing lifecycle methods. These
//   // are methods that will run over the lifecycle of the hook

//   const [registerUser, { loading }] = useMutation(REGISTER_USER, {
//     update(_, __) {
//       // after a successful registration we perform a redirect to the login page
//       props.history.push("/login");
//     },
//     onError(err) {
//       // this is how we can access our errors returned by our server. This is due to how we structured our errors
//       // on the server side

//       setErrors(err.graphQLErrors[0].extensions.errors);
//     },
//   });

//   const submitRegisterForm = (e) => {
//     e.preventDefault();
//     registerUser({ variables });
//     // when the registerUser mutation is executed we can see in the network tab the response from our server
//   };
//   return (
//     <Row className="bg-white py-5 justify-content-center">
//       <Col sm={8} md={6} lg={4}>
//         <h1 className="text-center">Register</h1>

//         {/* for each of our form inputs we check the errors object to see if the corresponding field is 
//         truthy. If it is then we add the classname of text-danger. We also change the label to display the 
//         error message */}

//         <Form onSubmit={submitRegisterForm}>
//           <Form.Group className="mb-3">
//             <Form.Label className={errors.email && "text-danger"}>
//               {errors.email ?? "Email address"}
//             </Form.Label>
//             <Form.Control
//               type="email"
//               value={variables.email}
//               className={errors.email && "is-invalid"}
//               onChange={(e) =>
//                 setVariables({ ...variables, email: e.target.value })
//               }
//             />
//           </Form.Group>
//           <Form.Group className="mb-3">
//             <Form.Label className={errors.username && "text-danger"}>
//               {errors.username ?? "Username"}
//             </Form.Label>
//             <Form.Control
//               type="text"
//               value={variables.username}
//               className={errors.username && "is-invalid"}
//               onChange={(e) =>
//                 setVariables({ ...variables, username: e.target.value })
//               }
//             />
//           </Form.Group>
//           <Form.Group className="mb-3">
//             <Form.Label className={errors.password && "text-danger"}>
//               {errors.password ?? "Password"}
//             </Form.Label>
//             <Form.Control
//               type="password"
//               value={variables.password}
//               className={errors.password && "is-invalid"}
//               onChange={(e) =>
//                 setVariables({ ...variables, password: e.target.value })
//               }
//             />
//           </Form.Group>
//           <Form.Group className="mb-3">
//             <Form.Label className={errors.confirmPassword && "text-danger"}>
//               {errors.confirmPassword ?? "Confirm password"}
//             </Form.Label>
//             <Form.Control
//               type="password"
//               value={variables.confirmPassword}
//               className={errors.confirmPassword && "is-invalid"}
//               onChange={(e) =>
//                 setVariables({
//                   ...variables,
//                   confirmPassword: e.target.value,
//                 })
//               }
//             />
//           </Form.Group>

//           <div className="text-center">
//             <Button variant="success" type="submit" disabled={loading}>
//               {loading ? "loading..." : "Register"}
//             </Button>
//             <br />
//             <small>
//               Already have an account ?<Link to="/login">Login</Link>
//             </small>
//           </div>
//         </Form>
//       </Col>
//     </Row>
//   );
// }
import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { baseUrl } from '../../App';
import "./register.css"
import axios from "axios";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
// import CheckBox from './../login/CheckBox';
export default function Register() {
  const username = useRef();
  const email = useRef();
  const password = useRef();
  const passwordAgain = useRef();
  const history = useHistory();
  const handleClick = async (e) => {
    e.preventDefault();
    if (passwordAgain.current.value !== password.current.value) {
      passwordAgain.current.setCustomValidity("Passwords don't match!");
    } else {
      const user = {
        username: username.current.value,
        email: email.current.value,
        password: password.current.value,
        password: password.current.value,
      };
      try {
        await axios.post(`${baseUrl}/api/register`, user);
        history.push("/login");
      } catch (err) {
        console.log(err);
        window.location.reload();
      }
    }
  };
  return (
    <div className="login-container">
      <div className="cardlogin">
        <div className="container">

          <form onSubmit={handleClick}>
            <h1>Register</h1>
            <label htmlFor="Username">
              Username
              <input
                type="username"
                minLength="3"
                placeholder="Username"
                autoComplete="off"
                required
                ref={username}
                className="loginInput"
              />
            </label>
            <label htmlFor="email">
              Email Address
              <input
                placeholder="Email"
                required
                ref={email}
                className="loginInput"
                type="email"
                autoComplete="off"
              />
            </label>
            <label htmlFor="password">
              Password
              <input
                placeholder="Password"
                required
                ref={password}
                className="loginInput"
                type="password"
                minLength="6"
              />
            </label>
            <label htmlFor="Password Again">
              Password Again
              <input
                placeholder="Password Again"
                required
                ref={passwordAgain}
                className="loginInput"
                type="password"
              />
            </label>
            <button className="btn btn-outline-success  btn-sm" type="submit">
              Sign Up
            </button>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => {
                history.push("/login");
              }}
            >
              Login
            </button>
            {/* <div><CheckBox/></div> */}
          </form>
        </div>
      </div>
    </div>
  );
}

// import "./register.css"
// import { useHistory } from 'react-router-dom';
// export default function Register(props) {
//   const history = useHistory();
//   const [variables, setVariables] = useState({
//     email: "",
//     username: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const [errors, setErrors] = useState({});

//   const registerUser = () => {

//     // make a network request to the server to register the user
//     fetch(`${baseUrl}/api/register`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(variables),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.errors) {
//           // if there are errors, set them in state
//           setErrors(data.errors);
//         } else {
//           // if registration is successful, redirect to login page
//           props.history.push("/login");
//         }
//       })
//       .catch((error) => console.log(error));
//   };

//   const submitRegisterForm = (e) => {
//     e.preventDefault();
//     registerUser();
//   };

//   return (
//     // <Row className="bg-white py-5 justify-content-center">

//     <div>
//       <div className="login-container">
//         <div className="cardlogin">
//           <div className="loginRight">
//             <form onSubmit={submitRegisterForm}>
//               <h1>ChatBubble
//               </h1>
//               <label htmlFor="Username">
//                 Username
//                 <input
//                   type="text"
//                   value={variables.username}
//                   className={errors.username && "is-invalid"}
//                   onChange={(e) =>
//                     setVariables({ ...variables, username: e.target.value })
//                   }
//                 />
//               </label>
//               <label htmlFor="email">
//                 Email Address
//                 <input
//                   type="email"
//                   value={variables.email}
//                   className={errors.email && "is-invalid"}
//                   onChange={(e) =>
//                     setVariables({ ...variables, email: e.target.value })
//                   }
//                 />
//               </label>
//               <label htmlFor="password">
//                 Password
//                 <input
//                   type="password"
//                   value={variables.password}
//                   className={errors.password && "is-invalid"}
//                   onChange={(e) =>
//                     setVariables({ ...variables, password: e.target.value })
//                   }
//                 />
//               </label>
//               <label htmlFor="Password Again">
//                 Password Again
//                 <input
//                   type="password"
//                   value={variables.confirmPassword}
//                   className={errors.confirmPassword && "is-invalid"}
//                   onChange={(e) =>
//                     setVariables({
//                       confirmPassword: e.target.value,
//                     })
//                   }
//                 />
//               </label>
//               <button className="loginRegisterButton" type="submit">
//                 Sign Up
//               </button>
//               <button
//                 className="btn btn-outline-danger btn-sm"
//                 onClick={() => {
//                   history.push("/login");
//                 }}
//               >
//                 Login
//               </button>
//               {/* <div><CheckBox/></div> */}
//             </form>
//           </div>
//         </div>
//       </div>

//     </div>
//   );
// }