import React, { useEffect, Fragment, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { io } from "socket.io-client";
import Message from "./Message";
import axios from "axios";
import { baseUrl } from '../../App';
import { PropagateLoader } from 'react-spinners';
import { useAuthDispatch, useAuthState } from "../../context/auth";
import { useMessageDispatch } from "../../context/message";
import Spinner from '../../components/spinner/Spinner';
import { ChatBubbleOutline } from "@mui/icons-material";

function Chat({ history }) {
    // const token = localStorage.getItem("token");
    const token = (localStorage.getItem("token"));
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const authDispatch = useAuthDispatch();
    const messageDispatch = useMessageDispatch();

    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [formattedMessages, setFormattedMessages] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    const [content, setContent] = useState("");
    const [error, setError] = useState("");
    const [socket, setSocket] = useState(null);

    const headers = new Headers({
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
    });

    useEffect(() => {
        getUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => console.log(error));
    }, []);

    const NEW_MESSAGE = {};

    // here we are aliasing the data and error variables
    const { data: messageData, error: messageError } = NEW_MESSAGE;

    useEffect(() => {
        if (messageError) console.log(messageError);

        if (messageData) {
            const message = messageData.newMessage;
            const otherUser =
                user.username === message.to ? message.from : message.to;

            messageDispatch({
                type: "ADD_MESSAGE",
                payload: {
                    username: otherUser,
                    message,
                },
            });
        }
    }, [messageError, messageData, user.username, messageDispatch]);

    useEffect(() => {
        const newMessages = messages?.filter(
            (message) => message.to === user.username || message.from === user.username
        );
        const otherUsers = newMessages?.map((message) =>
            message.to === user.username ? message.from : message.to
        );
        const uniqueOtherUsers = [...new Set(otherUsers)];

        const formattedMessages = uniqueOtherUsers?.map((username) => {
            const messagesWithUser = newMessages.filter(
                (message) => message.to === username || message.from === username
            );
            const lastMessage =
                messagesWithUser[messagesWithUser.length - 1]?.content;
            const formattedMessage = {
                username,
                message: {
                    content: lastMessage,
                    createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
                },
            };
            console.log(formattedMessage)
            return formattedMessage;
        });

        // Sort the formatted messages by the latest message createdAt timestamp
        const sortedMessages = formattedMessages.sort((a, b) => {
            const aTimestamp = a.message.createdAt || 0;
            const bTimestamp = b.message.createdAt || 0;
            return bTimestamp - aTimestamp;
        });

        setFormattedMessages(sortedMessages);
        setFormattedMessages(formattedMessages);
    }, [messages]);

    const logout = () => {
        authDispatch({ type: "LOGOUT" });
        window.location.href = "/login";
    };

    const getUsers = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/users/`, { headers });
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.log
                (error);
        }
    };

    useEffect(() => {
        getUsers();
        //ws?token=${token}/
        const ws = io("ws://localhost:4000");
        setSocket(ws);
        ws.addEventListener("message", (event) => {
            const message = JSON.parse(event.data);
            setMessages((prevMessages) => [...prevMessages, message]);
            setLoading(false);
        });

        return () => {
            ws.close();
        };
    }, []);

    useEffect(() => {
        if (selectedUser) {
            fetch(`${baseUrl}/api/messages/${selectedUser.username}`, { headers })
                .then((response) => response.json())
                .then((data) => setMessages(data))
                .catch((error) => console.log(error));

        }
    }, [selectedUser]);

    const submitMessage = async (e) => {
        e.preventDefault();
        if (!selectedUser) {
            setError("Recipient is required");
            return;
        }

        if (content.trim() === "") {
            setError("Message content is required");
            return;
        }

        try {
            const message = { from: user.username, to: selectedUser.username, content };
            socket.send(JSON.stringify(message));
            setContent("");
            setError("");
            const res = await axios.post(`${baseUrl}/api/messages`, message);
            setMessages((prevMessages) => [...prevMessages, res.data]);
        } catch (error) {
            console.log(error);
            setError("Failed to send message");
        }
    };

    let selectedChatMarkup;

    if (!messages) {
        selectedChatMarkup = <p className="info-text">Select a friend</p>;
    } else if (messages.length === 0) {
        selectedChatMarkup = (
            <p className="info-text">You are now connected! Send your first message</p>
        );
    } else {
        selectedChatMarkup = messages?.map((message, index) => (
            <Fragment key={message.id}>
                <Message message={message} />
                {index === messages.length - 1 && (
                    <div className="invisible">
                        <hr className="m-0" />
                    </div>
                )}
            </Fragment>
        ));
    }

    return (
        <Col xs={10} md={8}>
            {/* <div>
                {loading ? (
                    <div className="spinner-container">
                        <ChatBubbleOutline color="#123abc" />

                    </div>
                ) : (
                    <div>

                        {messages?.map((message, index) => (
                            <div key={index}>
                                <div>{message}</div>
                                <div className="invisible">
                                    <hr className="m-0" />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div> */}
            <div className="messages-box d-flex flex-column-reverse">
                {selectedUser ? (
                    selectedChatMarkup
                ) : (
                    <p className="info-text">Select a friend</p>
                )}
            </div>
            <div>
                <Form onSubmit={submitMessage}>
                    <Form.Group className="my-3 d-flex align-items-center">
                        <Form.Control
                            type="text"
                            className="message-input p-2 rounded-pill bg-secondary border-0"
                            placeholder="type a message ..."
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                        <i
                            className="fas fa-paper-plane fa-2x text-primary mx-2"
                            onClick={submitMessage}
                            role="button"
                        ></i>
                    </Form.Group>
                </Form>
            </div>
            <div className="users-list">
                <h5 className="mb-3">Friends</h5>
                {users?.map((user) => (
                    <div
                        key={user.id}
                        className={`user ${selectedUser?.username === user.username ? "selected" : ""}`}
                        onClick={() => setSelectedUser(user)}
                        role="button"
                    >
                        <div className="user-info">
                            <div className="username">{user.username}</div>
                            {formattedMessages[0]?.username === user.username ? (
                                <>
                                    <span className="content">{formattedMessages[0]?.message.content}</span>
                                </>
                            ) : (
                                <span className="no-message"><Spinner /></span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Col>
    );
}

export default Chat;