
// import React from "react";
// import { useEffect } from "react";
// import { useState } from "react";
// import { Col, Image } from "react-bootstrap";
// import classNames from "classnames";
// import { useMessageDispatch, useMessageState } from "../../context/message";
// import { baseUrl } from "../../App";

// function Users() {

//   const dispatch = useMessageDispatch();
//   const { users } = useMessageState();
//   const selectedUser = users?.find((user) => user.selected === true);
//   const [data, setData] = useState("");
//   // const [selectedUser, setSelectedUser] = useState(null);
//   const token = localStorage.getItem("token");

//   const [messages, setMessages] = useState([]);
//   const [latestMessage, setLatestMessage] = useState("");

//   const headers = new Headers({
//     'Accept': 'application/json',
//     'Authorization': `Bearer ${token}`
//   });

//   useEffect(() => {
//     if (selectedUser) {
//       // fetch messages for selected user from backend
//       fetch(`${baseUrl}/api/messages/${selectedUser.username}`, {
//         headers: headers
//       })
//         .then((response) => response.json())
//         .then((messages) => {
//           setMessages(messages);

//           // set latest message for selected user
//           const latestMessage = messages.length > 0 ? messages[messages.length - 1] : null;
//           setLatestMessage(latestMessage);
//           dispatch({ type: "SET_LATEST_MESSAGE", payload: { username: selectedUser.username, latestMessage: latestMessage } });

//         })
//         .catch((error) => console.log(error));

//     }

//   }, [selectedUser]);
//   console.log(selectedUser)
//   async function getUserData() {
//     // const user = localStorage.getItem("user");


//     const data = await fetch(`${baseUrl}/api/users`, {
//       headers: headers
//     });
//     const response = await data.json();
//     setData(response);
//     localStorage.setItem('users', JSON.stringify(response))
//   }

//   useEffect(() => {
//     getUserData();
//   }, []);

//   let usersMarkup;
//   if (!users) {
//     usersMarkup = <p>Loading ...</p>;
//   } else if (users.length === 0) {
//     usersMarkup = <p>No users have joined yet</p>;
//   } else if (users.length > 0) {

//     usersMarkup = users.map((user) => {

//       const selected = latestMessage?.from === user.username ||
//         latestMessage?.to === user.username;
//       // const sent = message && message.from === user.username;
//       console.log(latestMessage)
//       return (
//         <div
//           role="button"
//           className={classNames(
//             "user-div d-flex p-3 justify-content-center justify-content-md-start",

//             // console.log(classNames("user-div", { "bg-white": selected }))
//           )}
//           key={user.username}
//           onClick={() => {
//             // console.log("Clicked on user:", user.username);
//             dispatch({ type: "SET_SELECTED_USER", payload: user.username })
//           }
//           }
//         >
//           <Image
//             src={
//               user.imageUrl ||
//               ""
//             }
//             className="me-2 user-image"
//           />

//           <div className="d-none d-md-block">
//             <p className="text-success">{user.username}</p>
//             <p className="font-weight-light">
//               {selected
//                 ? latestMessage.content
//                 : "You are now connected"}
//             </p>



//           </div>
//         </div>
//       );
//     });
//   }
//   return (
//     <Col xs={2} md={4} className="p-0 bg-secondary">

//       {usersMarkup}
//     </Col>
//   );
// }

// export default Users;
import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import classNames from "classnames";
import { useMessageDispatch, useMessageState } from "../../context/message";
import { baseUrl } from "../../App";
import jwtDecode from "jwt-decode";
import Spinner from './../../components/spinner/Spinner';
import Message from "../../components/message/Message";
import Messages from './../messages/Messages';

function Users() {
  const dispatch = useMessageDispatch();
  const [data, setData] = useState("");
  const { users } = useMessageState();


  const token = (localStorage.getItem("token"));
  const [messages, setMessages] = useState([]);
  const [latestMessage, setLatestMessage] = useState("");
  const [loading, setLoading] = useState(true);
  console.log(data)
  const headers = new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });

  const removeToken = () => {
    localStorage.removeItem("token");
  };
  const selectedUser = users?.find((user) => user.selected === true);
  useEffect(() => {
    if (selectedUser) {
      setLoading(true);
      fetch(`${baseUrl}/api/messages/${selectedUser.username}`, {
        headers: headers,
      })
        .then((response) => response.json())
        .then((messages) => {
          setMessages(messages);


          const latestMessage = messages.length > 0 ? messages[messages.length - 1] : null;
          setLatestMessage(latestMessage);
          dispatch({
            type: "SET_LATEST_MESSAGE",
            payload: { username: selectedUser.username, latestMessage: latestMessage },
          });
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }

    console.log(messages)
  }, [selectedUser]);
  async function getUserData() {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      const expiresAt = new Date(decodedToken.exp * 1000);

      if (new Date() > expiresAt) {
        removeToken();
        dispatch({ type: "LOGOUT" });
      } else {
        dispatch({
          type: "LOGIN",
          payload: {
            token,
            ...decodedToken,
          },
        });

        fetch(`${baseUrl}/api/users`, {
          headers: headers,
        })
          .then((response) => response.json())
          .then((response) => {
            setData(response);
            localStorage.setItem("users", JSON.stringify(response));
            setLoading(false);
          })
          .catch((error) => console.log(error));
      }
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  let usersMarkup;
  if (loading) {
    usersMarkup = <p>Loading ...</p>;
  } else if (data.length === 0) {
    usersMarkup = <Spinner />
  } else if (data.length === 0) {
    usersMarkup = <p>No users have joined yet</p>;
  } else if (data.length > 0) {
    usersMarkup = data.map((user) => {
      const selected =
        latestMessage?.from === user.username || latestMessage?.to === user.username;
      return (
        <div
          role="button"
          className={classNames("user-div d-flex p-3 justify-content-center justify-content-md-start", {
            "bg-white": selected,
          })}
          key={user.username}
          onClick={() => dispatch({ type: "SET_SELECTED_USER", payload: user.username })}
        >
          <Image src={user.imageUrl || ""} className="me-2 user-image" />
          <div className="d-none d-md-block">
            <p className
              ="text-success">{user.username}</p>
            <p className="font-weight-light">
              {selected ? latestMessage.content : "You are now connected"}
            </p>
          </div>


        </div>
      );
    });


  }

  return <Col xs={2} md={4} className="p-0 bg-secondary">{usersMarkup}</Col>;
}

export default Users;
