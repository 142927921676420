import React, { useState } from "react";
import styles from "./Login.module.css";
import axios from "axios";
import { baseUrl } from "./../../App";
import { useAuthDispatch } from "./../../context/auth";
import { useHistory } from "react-router-dom";
import { Alert } from 'reactstrap';
import LoadingSpinner from "../../components/LoadingSpinner";


const Login = () => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const [error, setError] = useState(null); // Added error state
  const dispatch = useAuthDispatch();

  const token = localStorage.getItem("token") || null;
  const headers = new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });

  const submitLoginForm = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/login`,
        {
          username,
          password,
        },
        { headers: headers }
      );

      window.location.reload();
      history.push("/");

      if (data) {
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("token", JSON.stringify(data.token));
        dispatch({ type: "LOGIN", payload: data });
      }
    } catch (err) {
      if (err.response) {
        setError("Invalid username or password");
      } else {
        setError("Oops! We're having trouble connecting to our server right now. Please try again later.");
      }
    }finally {
      setLoading(false); // Set loading to false when done
    }
  };

  return (
    <div className={styles.container}>
      <h2>ChatBubble</h2>

      <form className="loginBox" onSubmit={submitLoginForm}>
        <input
          className="loginInput"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="loginInput"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="loginButton" type="submit" disabled={loading}>

          {loading ? "Logging In..." : "Log In"}
        </button>
      </form>
      {/* <Alert color="danger">{error}</Alert> */}
      {loading ?(<LoadingSpinner/>):(error &&  error && <Alert  color="danger">{error}</Alert> )}
     {/* Display error message */}
      <div className={styles.logininfo}>
        <small>Don&apos;t have an account ?</small>
        <button
          className="loginRegisterButton"
          onClick={() => {
            history.push("/register");
          }}
        >
          Register
        </button>
        <p>Téléchargez l&apos;application.</p>
      </div>
    </div>
  );
};

export default Login;
