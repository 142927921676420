// import React, { Fragment, useEffect } from "react";
// import { Button, Nav, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { useSubscription, gql } from "@apollo/client";

// import { useAuthDispatch, useAuthState } from "../../context/auth";
// import { useMessageDispatch } from "../../context/message";

// import Users from "./Users";
// import Messages from "./Messages";

// const NEW_MESSAGE = gql`
//   subscription newMessage {
//     newMessage {
//       uuid
//       from
//       to
//       content
//       createdAt
//     }
//   }
// `;

// export default function Home({ history }) {
//   const authDispatch = useAuthDispatch();
//   const messageDispatch = useMessageDispatch();

//   const { user } = useAuthState();

//   // here we are aliasing the data and error variables
//   const { data: messageData, error: messageError } =
//     useSubscription(NEW_MESSAGE);

//   useEffect(() => {
//     if (messageError) console.log(messageError);

//     if (messageData) {
//       const message = messageData.newMessage;
//       const otherUser =
//         user.username === message.to ? message.from : message.to;

//       messageDispatch({
//         type: "ADD_MESSAGE",
//         payload: {
//           username: otherUser,
//           message,
//         },
//       });
//     }
//   }, [messageError, messageData]);

//   const logout = () => {
//     authDispatch({ type: "LOGOUT" });
//     // now when we log out the entire app will be reloaded. This was an easy fix to the logout login and the
//     // users being displayed wrong issue
//     window.location.href = "/login";
//   };

//   return (
//     <Fragment>
//       <Nav className="justify-content-around bg-white mb-1">
//         <Link to="/login">
//           <Button variant="link">Login</Button>
//         </Link>
//         <Link to="/register">
//           <Button variant="link">Register</Button>
//         </Link>
//         <Button variant="link" onClick={logout}>
//           Logout
//         </Button>
//       </Nav>
//       <Row className="bg-white mx-0">
//         <Users />
//         <Messages />
//       </Row>
//     </Fragment>
//   );
// }
// import React, { Fragment, useEffect, useState } from "react";
// import { Button, Nav, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";

// import { useAuthDispatch, useAuthState } from "../../context/auth";
// import { useMessageDispatch } from "../../context/message";
// import { getUsers, getMessages } from "../../api";

// import Users from "./Users";
// import Messages from "./Messages";

// export default function Home({ history }) {
//   const authDispatch = useAuthDispatch();
//   const messageDispatch = useMessageDispatch();

//   const [users, setUsers] = useState([]);
//   const [messages, setMessages] = useState([]);

//   const { user } = useAuthState();

//   useEffect(() => {
//     getUsers()
//       .then((data) => {
//         setUsers(data);
//       })
//       .catch((error) => console.log(error));

//     getMessages()
//       .then((data) => {
//         setMessages(data);
//       })
//       .catch((error) => console.log(error));
//   }, []);

//   useEffect(() => {
//     const newMessages = messages.filter(
//       (message) => message.to === user.username || message.from === user.username
//     );
//     const otherUsers = newMessages.map((message) =>
//       message.to === user.username ? message.from : message.to
//     );
//     const uniqueOtherUsers = [...new Set(otherUsers)];

//     const formattedMessages = uniqueOtherUsers.map((username) => {
//       const messagesWithUser = newMessages.filter(
//         (message) =>
//           message.to === username || message.from === username
//       );
//       const lastMessage =
//         messagesWithUser[messagesWithUser.length - 1]?.content;
//       const formattedMessage = {
//         username,
//         message: {
//           content: lastMessage,
//           createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
//         },
//       };
//       return formattedMessage;
//     });

//     setMessages(formattedMessages);
//   }, [user]);

//   const logout = () => {
//     authDispatch({ type: "LOGOUT" });
//     window.location.href = "/login";
//   };

//   return (
//     <Fragment>
//       <Nav className="justify-content-around bg-white mb-1">
//         <Link to="/login">
//           <Button variant="link">Login</Button>
//         </Link>
//         <Link to="/register">
//           <Button variant="link">Register</Button>
//         </Link>
//         <Button variant="link" onClick={logout}>
//           Logout
//         </Button>
//       </Nav>
//       <Row className="bg-white mx-0">
//         <Users users={users} />
//         <Messages messages={messages} />
//       </Row>
//     </Fragment>
//   );
// }
import React, { Fragment, useEffect, useState } from "react";
import { Button, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import { useAuthDispatch, useAuthState } from "../../context/auth";
// import { useMessageDispatch } from "../../context/message";
// import { getUsers, getMessages } from "../../api";

import Users from "./Users";

import { baseUrl } from './../../App';
import Messages from './../messages/Messages';
import Topbar from "../../components/topbar/Topbar";
import Share from './../../components/share/Share';
import Rightbar from './../../components/rightbar/Rightbar';
import Sidebar from './../../sidebar/Sidebar';
import Post from './../../components/post/Post';
import "../../App.css";
import { AuthContext } from './../../App';
import "./home.css";
import Feed from "../../components/feed/Feed";

import { useAuthDispatch, useAuthState } from "../../context/auth";
import { useMessageDispatch } from "../../context/message";

import axios from "axios";
import ChatOnline from "../../components/chatOnline/ChatOnline";








export const Home = ({ messages }) => {

  const [selectedUser, setSelectedUser] = useState(null);
  const { user } = useAuthState();
  const [users, setUsers] = useState([]);
  const [mmessages, setMessages] = useState([]);
  const { state: authState } = useAuthState();

  const token = localStorage.getItem("token");
  const headers = new Headers({
    "Accept": "application/json",
    "Authorization": `Bearer ${token}`
  });
  const initialState = {
    posts: [],
    isFetching: false,
    hasError: false,
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_POSTS_REQUEST":
        return {
          ...state,
          isFetching: true,
          hasError: false,
        };
      case "FETCH_POSTS_SUCCESS":
        return {
          ...state,
          isFetching: false,
          posts: action.payload,
        };
      case "FETCH_POSTS_FAILURE":
        return {
          ...state,
          hasError: true,
          isFetching: false,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const getMessages = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/messages/${user.username}`, { headers });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   getUsers()
  //     .then((data) => {
  //       setUsers(data);
  //     })
  //     .catch((error) => console.log(error));
  // }, []); // Add an empty dependency array to run this effect only once on mount

  useEffect(() => {
    getMessages()
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => console.log(error));
  }, []); // Add an empty dependency array to run this effect only once on mount

  const [formattedMessages, setFormattedMessages] = useState([]);
  const [formattedMessage, setFormattedMessage] = useState({});
  useEffect(() => {
    if (selectedUser && Array.isArray(messages)) {
      const newMessages = messages.filter(
        (message) =>
          message.to === user.username || message.from === user.username
      );
      const otherUsers = newMessages.map((message) =>
        message.to === user.username ? message.from : message.to
      );
      const uniqueOtherUsers = [...new Set(otherUsers)];

      const formattedMessages = uniqueOtherUsers.map((username) => {
        const messagesWithUser = newMessages.filter(
          (message) => message.to === username || message.from === username
        );
        const lastMessage =
          messagesWithUser[messagesWithUser.length - 1]?.content;
        const formattedMessage = {
          username,
          message: {
            content: lastMessage,
            createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
          },
        };

        return formattedMessage;
      });
    } else {
      // Handle the case when messages is not an array
      // For example, you can set formattedMessages to an empty array
      setFormattedMessages([]);
    }
    setFormattedMessages(formattedMessages);
  }, [user, messages]);
  console.log(formattedMessages)
  formattedMessages.forEach((formattedMessage) => {
    console.log(formattedMessage?.message.content);
  });
  useEffect(() => {
    if (selectedUser) {
      axios
        .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
        .then((response) => {
          setMessages(response.data);

        })
        .catch((error) => console.log(error));
    }
  }, [selectedUser]);
  console.log(messages)

  // const logout = () => {
  //   useAuthDispatch({ type: "LOGOUT" });
  //   window.location.href = "/login";
  // };
  const useLogout = () => {
    const authDispatch = useAuthDispatch();

    const logout = () => {
      authDispatch({ type: "LOGOUT" });
      window.location.href = "/login";
    };

    return logout;
  };

  React.useEffect(() => {
    dispatch({
      type: "FETCH_POSTS_REQUEST",
    });
    fetch(`${baseUrl}/api/posts/`, {
      headers

    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatch({
          type: "FETCH_POSTS_SUCCESS",
          payload: resJson,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "FETCH_POSTS_FAILURE",
        });
      });
  }, [authState?.token]);
  return (
    <div >
      {/* <Topbar /> */}
      {/* <div className="homeContainer">
        <div className="sid">
          <Sidebar />


        </div>


        < Feed />
        <div className="ritgh">
          <Rightbar />
        </div>
      </div> */}
      {/* <Topbar messages={messages} users={users} /> */}
      <div className="homeContainer">

        <div className="row ">
          <div className="col-sm-4 sid">
          <Sidebar />

            {/* <Users users={users} messages={messages} /> */}
            <ChatOnline />

          </div>
          <div className="col-sm-4">
            < Feed />
          </div>
          <div className="col-sm-4">
            <Rightbar />

          </div>
        </div>
      </div>

      {state.isFetching ? (
        <span variant="link" onClick={useLogout} className="loader">LOADING...</span>
      ) : state.hasError ? (
        <span className="error">AN ERROR HAS OCCURED</span>
      ) : (
            <>

          {/* {state.posts.map((post) => (
                <Post key={post.id.toString()} post={post} />
              ))} */}


        </>
      )}
    </div>

  );
};
export default Home;