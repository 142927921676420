import "./message.css";
// import { format } from "timeago.js";
import React from "react";
import { useAuthState } from "../../context/auth";
import classNames from "classnames";
import moment from "moment";
import { baseUrl } from "../../App";
export default function Message({ message, own }) {
  const { user } = useAuthState();
  const sent = message && message.from === user.username;
  const received = message && !sent;
  const PF = `${baseUrl}/images/`;

  return (
    <div className={own ? "message own" : "message"}>

      <div
        className={classNames("d-flex my-3", {
          "ms-auto": sent,
          "me-auto": received,
        })}
      >

        <img
          className="rightbarProfileImg"
          // src="https://images.pexels.com/photos/3686769/pexels-photo-3686769.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
          src={
            sent
              ? (user.profilePicture
                ? PF + user.profilePicture // If user has profile picture, use it
                : PF + "man.png" // If user does not have a profile picture, use default image 1
              )
              : (received
                ? (message.fromProfilePicture
                  ? PF + message.fromProfilePicture // If sender has profile picture, use it
                  : PF + "hacker.png"// If sender does not have a profile picture, use default image 1
                )
                : ""
              )
          }


          alt=""
        />
        <div
          className={classNames("py-2 px-3 rounded-pill", {
            "bg-info": sent,
            "bg-warning": received,
          })}
        >

          <p className="text-dark" sent="true">{message.content}</p>

          <div className="timestamp">{moment(message.createdAt).format("MMM DD, YYYY @ h:mm a")}</div>
        </div>
      </div>
    </div>



  );
}
// import "./message.css";
// // import { format } from "timeago.js";
// import React from "react";
// import { useAuthState } from "../../context/auth";
// import classNames from "classnames";
// import moment from "moment";
// import { baseUrl } from "../../App";
// export default function Message({ message, own }) {
//   const { user } = useAuthState();
//   const sent = message && message.from === user.username;
//   const received = message && !sent;
//   const PF = `${baseUrl}/images/`;

//   return (
//     <div className={own ? "message own" : "message"}>
//       <div className="messageTop">
//         <img
//           className="messageImg"
//           src="https://images.pexels.com/photos/3686769/pexels-photo-3686769.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
//           alt=""
//         />
//         <p className="messageText">{message.content}</p>
//       </div>
//       <div
//         className={classNames("d-flex my-3", {
//           "ms-auto": sent,
//           "me-auto": received,
//         })}
//       >
//         <div className={own ? "message own" : "message"}>
//           <img
//             className="rightbarProfileImg"
//             // src="https://images.pexels.com/photos/3686769/pexels-photo-3686769.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
//             src={
//               sent
//                 ? (user.profilePicture
//                   ? PF + user.profilePicture // If user has profile picture, use it
//                   : PF + "man.png" // If user does not have a profile picture, use default image 1
//                 )
//                 : (received
//                   ? (message.fromProfilePicture
//                     ? PF + message.fromProfilePicture // If sender has profile picture, use it
//                     : PF + "hacker.png"// If sender does not have a profile picture, use default image 1
//                   )
//                   : ""
//                 )
//             }


//             alt=""
//           />
//           <div
//             className={classNames("py-2 px-3 rounded-pill", {
//               "bg-primary": sent,
//               "bg-secondary": received,
//             })}
//           >
//             <p className={classNames({ "text-white": sent })}>{message.content}</p>
//             <div className="timestamp">{moment(message.createdAt).format("MMM DD, YYYY @ h:mm a")}</div>
//           </div>
//         </div>
//       </div>
//       {/* <div className="messageBottom">{format(message.createdAt)}</div> */}
//     </div>

//   );
// }
