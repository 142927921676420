import React, { useEffect, useState, useRef } from 'react';
import Peer from 'simple-peer';
import io from 'socket.io-client';
// import { baseUrl } from '../../App';
// import { baseUrl } from './Messages';

const debug = require('debug')('app:app');


const nodeEnv = process.env.NODE_ENV;
let ioUrl;

if (nodeEnv === 'production') {
    ioUrl = process.env.REACT_APP_IO_URL || 'wss://sds-2a76.onrender.com/';
} else {
    ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
}

export const baseUrl = ioUrl;
const enableTrickle = true;

const VideoCall = () => {
    const [peers, setPeers] = useState({});
    const [stream, setStream] = useState(null);
    const videoRef = useRef(null);

    useEffect(() => {
        const getMedia = async () => {
            try {
                const options = { video: true, audio: true };
                const stream = await navigator.mediaDevices.getUserMedia(options);
                setStream(stream);
            } catch (error) {
                console.error('Could not access webcam', error);
            }
        };

        getMedia();
    }, []);

    useEffect(() => {
        if (stream && videoRef.current && !videoRef.current.srcObject) {
            videoRef.current.srcObject = stream;
        }

        attachPeerVideos();
    }, [stream]);

    useEffect(() => {
        const socket = io(ioUrl);

        socket.on('peer', (msg) => {
            const peerId = msg.peerId;
            debug('new peer poof!', peerId);

            if (peerId === socket.id) {
                return debug('Peer is me :D', peerId);
            }

            createPeer(peerId, true, stream);
        });

        socket.on('signal', (data) => {
            const peerId = data.from;
            const peer = peers[peerId];

            if (!peer) {
                createPeer(peerId, false, stream);
            }

            debug('Setting signal', peerId, data);
            signalPeer(peers[peerId], data.signal);
        });

        socket.on('unpeer', (msg) => {
            debug('Unpeer', msg);
            destroyPeer(msg.peerId);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const attachPeerVideos = () => {
        Object.entries(peers).forEach(([peerId, peer]) => {
            if (peer.video && !peer.video.srcObject && peer.stream) {
                debug('setting peer video stream', peerId, peer.stream);
                peer.video.setAttribute('data-peer-id', peerId);
                peer.video.srcObject = peer.stream;
            }
        });
    };

    const createPeer = (peerId, initiator, stream) => {
        debug('creating new peer', peerId, initiator);

        const peer = new Peer({ initiator, trickle: enableTrickle, stream });

        peer.on('signal', (signal) => {
            const socket = io(ioUrl);
            const msgId = new Date().getTime();
            const msg = { msgId, signal, to: peerId };
            debug('peer signal sent', msg);
            socket.emit('signal', msg);
        });

        peer.on('stream', (stream) => {
            debug('Got peer stream!!!', peerId, stream);
            peer.stream = stream;
            setPeerState(peerId, peer);
        });

        peer.on('connect', () => {
            debug('Connected to peer', peerId);
            peer.connected = true;
            setPeerState(peerId, peer);
            peer.send(serialize({
                msg: 'hey man!'
            }));
        });

        peer.on('data', (data) => {
            debug('Data from peer', peerId, unserialize(data));
        });

        peer.on('error', (e) => {
            debug('Peer error %s:', peerId, e);
        });

        setPeerState(peerId, peer);

        return peer;
    };

    const destroyPeer = (peerId) => {
        const updatedPeers = { ...peers };
        delete updatedPeers[peerId];
        setPeers(updatedPeers);
    };

    const serialize = (data) => JSON.stringify(data);

    const unserialize = (data) => {
        try {
            return JSON.parse(data.toString());
        } catch (e) {
            return undefined;
        }
    };

    const setPeerState = (peerId, peer) => {
        setPeers((prevPeers) => ({ ...prevPeers, [peerId]: peer }));
    };

    const signalPeer = (peer, data) => {
        try {
            peer.signal(data);
        } catch (e) {
            debug('signal error', e);
        }
    };

    const renderPeers = () => {
        return Object.entries(peers).map(([peerId, peer]) => {
            debug('render peer', peerId, peer);
            return (
                <div key={peerId}>
                    <video ref={(video) => (peer.video = video)}></video>
                </div>
            );
        });
    };

    return (
        <div className="App">
            <header className="App-header">
                <img    src={`${baseUrl}/images/` + "11.png"} className="App-logo" alt="logo" />
                <p className="App-title">WebRTC Video Chat</p>
            </header>
            {stream && videoRef.current && !videoRef.current.srcObject && (
                <p className="error">Could not access webcam</p>
            )}
            <div id="me">
                <video id="myVideo" ref={videoRef} controls></video>
            </div>
            <div id="peers">{renderPeers()}</div>
        </div>
    );
};

export default VideoCall;
