import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import "./profile.css";
import { useParams, useHistory } from "react-router-dom";
import { AddAPhoto } from "@material-ui/icons";
import Feed from "../../components/feed/Feed";
import { AuthContext, baseUrl } from "./../../App";
import UpdateProfilePhoto from "./UpdateProfilePhoto";
import UpdateProfileUsername from "./UpdateProfileUsername";
import UpdateProfileEmail from "./UpdateProfileEmail";
import Rightbar from "./../../components/rightbar/Rightbar";
import { useAuthState } from "../../context/auth";

import axios from "axios";
import Sidebar from "../../sidebar/Sidebar";
// ^\s*$\n

export default function Profile(post) {
  // const userPosts = Posts.find((post) => user.id === post.userId);

  //  const  curentpost = post && post.userId === user.id;
  //   const otherpost = post && !curentpost;
  const url = `${baseUrl}/images/`;
  let { id } = useParams();
  const username = useParams().username;
  let history = useHistory();
  // const user = useAuthState();
  // const { state } = React.useContext(AuthContext);
  // const user = JSON.parse(localStorage.getItem("user"));
  const [Posts, setPosts] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const users = JSON.parse(localStorage.getItem("userAount"));
  // const fuserPosts = users.filter((f) => Posts?.some((p) => p.userId !== f));
  console.log(user);
  const token = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const [data, setData] = useState("");
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [showUpdatePhoto, setShowUpdatePhoto] = useState(false);
  const [showUpdateEmail, setShowUpdateEmail] = useState(false);
  const [showUpdateUsername, setShowUpdateUsername] = useState(false);
  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     axios
  //       .get(`${baseUrl}/api/posts`, {
  //         headers,
  //       })
  //       .then((response) => {
  //         setPosts(response.data);
  //         localStorage.setItem(
  //           " setLtOfPtsbyuserId",
  //           JSON.stringify(response.data)
  //         );
  //       });
  //   };
  //   fetchPosts();
  // }, []);
  async function handleUpdateProfilePhoto(data) {
    const formData = new FormData();
    formData.append("image", data.image[0]);
    const sendPhoto = await fetch(`${baseUrl}/api/users/${user.id}`, {
      method: "put",
      headers,
      body: formData,
    });
    const response = await sendPhoto.json();
    console.log(response);
    //     getPostData()
    window.location.reload();
    setShowUpdatePhoto(false);
  }
  async function handleUpdateProfileEmail(data) {
    console.log(data);
    // const { userId } = user
    //     const sendedEmail = await fetch(
    //       "http://localhost:4000/api/profile/" + user.id,
    //       {
    //         method: "put",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Bearer " + token,
    //         },
    //         body: JSON.stringify(data),
    //       }
    //     );
    //     const response = await sendedEmail.json();

    getUserData();
    setShowUpdateEmail(false);
  }
  async function handleUpdateProfileUsername(data) {
    const sendedUsername = await fetch(
      `${baseUrl}/api/profile/${user.username}`,
      {
        method: "put",
        headers,
        body: JSON.stringify(data),
      }
    );
    console.log(data);
    const response = await sendedUsername.json();
    getUserData();
    setShowUpdateUsername(false);
    console.log(response);
  }
  async function getUserData() {
    const URL = `${baseUrl}/api/users`;
    const data = await fetch(URL, {
      headers,
    });
    const response = await data.json();
    setData(response);
    console.log(response);
  }
  useEffect(() => {
    getUserData();
    console.log(data);
  }, []);
  // console.log(fuserPosts);
  return (
    <div>
      <div className="profile">
        <div className="profileRight">
          <div className="profileRightTop">
            <div className="profileCover">
              <img
                className="profileCoverImg"
                src={
                  user.coverPicture ? url + user.coverPicture : url + "/ANI.PNG"
                }
                alt=""
              />
              <img
                className="profileUserImg"
                src={
                  user.profilePicture
                    ? url + user.profilePicture
                    : url + "person/noAvatar.png"
                }
                alt=""
              />
              <div className="profileUserImgChange">
                <AddAPhoto
                  onClick={() => {
                    setShowUpdatePhoto(!showUpdatePhoto);
                    setShowUpdateEmail(false);
                    setShowUpdateUsername(false);
                  }}
                />
              </div>
            </div>
            <div>
              {errors.message && errors.message.message}
              {showUpdatePhoto && (
                <UpdateProfilePhoto
                  submit={handleSubmit(handleUpdateProfilePhoto)}
                  register={register({ required: true })}
                />
              )}
              {showUpdateUsername && (
                <UpdateProfileUsername
                  submit={handleSubmit(handleUpdateProfileUsername)}
                  register={register({ required: true })}
                />
              )}
              {showUpdateEmail && (
                <UpdateProfileEmail
                  submit={handleSubmit(handleUpdateProfileEmail)}
                  register={register({ required: true })}
                />
              )}
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="profile">
        <Sidebar />
        <div className="profileRight">
          <div className="profileRightTop">
            <div className="profileInfo">
              <h4 className="profileInfoName">{user.username}</h4>
              <span className="profileInfoDesc">{user.email}</span>
              <span className="profileInfoDesc">
                Profil crée le :{" "}
                {user.createdAt?.split("T").join(" à ").split(".000Z")}
              </span>
              <span className="profileInfoDesc">
                Profil Modifié le :{" "}
                {user.createdAt?.split("T").join(" à ").split(".000Z")}
              </span>
            </div>
          </div>
          <div className="profileRightBottom">
            <Feed username={username} />
            <Rightbar user={user} />
          </div>
        </div>
      </div>
    </div>
  );
}
