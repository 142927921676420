// import React from "react";
// import { useAuthState } from "../../context/auth";
// import classNames from "classnames";
// import moment from "moment";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";

// // component for displaying a single message

// function Message({ message }) {
//   const { user } = useAuthState();
//   //   logic to determine if a message is received or sent. We make use of the authContext to get our own username
//   //   we will use classnames package to apply conditional classes to distinguish sent messages from received messages
//   const sent = message && message.from === user.username;
//   const received = message && !sent;

//   // here we are using an overlay trigger that we copied from the react bootstrap docs. This is to provide a
//   // tooltip where we hover over messages

//   return (
//     <OverlayTrigger
//       placement="top"
//       overlay={
//         <Tooltip>
//           {moment(message.createdAt).format("MMM DD, YYYY @ h:mm a")}
//         </Tooltip>
//       }
//     >
//       <div
//         className={classNames("d-flex my-3", {
//           "ms-auto": sent,
//           "me-auto": received,
//         })}
//       >
//         <div
//           className={classNames("py-2 px-3 rounded-pill", {
//             "bg-primary": sent,
//             "bg-secondary": received,
//           })}
//         >
//           <p className={classNames({ "text-white": sent })} key={message.uuid}>
//             {message.content}
//           </p>
//         </div>
//       </div>
//     </OverlayTrigger>
//   );
// }

// export default Message;
import React from "react";
import { useAuthState } from "../../context/auth";
import classNames from "classnames";
import moment from "moment";
import { Tooltip } from "react-bootstrap";
import { baseUrl } from "../../App";

function Message({ message, own }) {
  const { user } = useAuthState();
  const sent = message && message.from === user.username;
  const received = message && !sent;
  const PF = `${baseUrl}/images/`;

  return (
    <div
      className={classNames("d-flex my-3", {
        "ms-auto": sent,
        "me-auto": received,
      })}
    >
      <div className={own ? "message own" : "message"}>
        <img
          className="rightbarProfileImg"
          // src="https://images.pexels.com/photos/3686769/pexels-photo-3686769.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
          src={
            sent
              ? (user.profilePicture
                ? PF + user.profilePicture // If user has profile picture, use it
                : PF + "man.png" // If user does not have a profile picture, use default image 1
              )
              : (received
                ? (message.fromProfilePicture
                  ? PF + message.fromProfilePicture // If sender has profile picture, use it
                  : PF + "hacker.png"// If sender does not have a profile picture, use default image 1
                )
                : ""
              )
          }


          alt=""
        />
        <div
          className={classNames("py-2 px-3 rounded-pill", {
            "bg-primary": sent,
            "bg-secondary": received,
          })}
        >
          <p className={classNames({ "text-white": sent })}>{message.content}</p>
          <div className="timestamp">{moment(message.createdAt).format("MMM DD, YYYY @ h:mm a")}</div>
        </div>
      </div>
    </div>
  );
}

export default Message;
