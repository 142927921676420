// import React, { useEffect, useRef, useState } from "react";
// import socketIOClient from " socket.current.io-client";
// import notificationSound from "./../../assets/notif.wav";
// import Row from "react-bootstrap/Row";
// import axios from "axios";
// import Col from "react-bootstrap/Col";
// import ListGroup from "react-bootstrap/ListGroup";
// import Alert from "react-bootstrap/Alert";
// import Badge from "react-bootstrap/Badge";
// import FormControl from "react-bootstrap/FormControl";
// import InputGroup from "react-bootstrap/InputGroup";
// import Button from "react-bootstrap/Button";
// import { baseUrl } from "../../App";
// import { useAuthState } from "../../context/auth";
// import "../../App.css"
// import { io } from ' socket.current.io-client';
// import { w3cwebsocket as WebSocket } from 'websocket';
// const ENDPOINT =
//     window.location.host.indexOf("localhost") >= 0
//         ? "http://127.0.0.1:4000"
//         : window.location.host;

// export default function AdminPage() {
//     const notificationAudio = new Audio(notificationSound);
//     const [content, setContent] = useState("");
//     const [error, setError] = useState("");
//     // const { user } = useAuthState();
//     const [selectedUser, setSelectedUser] = useState({});
//     const socketRef = useRef(null);
//     const uiMessagesRef = useRef(null);

//     const [messages, setMessages] = useState([]);
//     const [users, setUsers] = useState([]);
//     const [isOnline, setIsOnline] = useState(false);
//     const token = JSON.parse(localStorage.getItem("token"));
//     const user = JSON.parse(localStorage.getItem("user"));


//     const headers = new Headers({
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//     });


//     const userId = user?.id;
//     const getUsers = async () => {
//         try {
//             const data = await fetch(`${baseUrl}/api/users/`, {
//                 headers: headers
//             });
//             const response = await data.json();
//             console.log(response);
//             return response;
//         } catch (error) {
//             console.error(error);
//             throw error; // Rethrow the error to be caught by the caller
//         }
//     };


//     const getPosts = () => {
//         return new Promise((resolve, reject) => {
//             fetch(`${ENDPOINT}/api/posts/`, { headers })
//                 .then((response) => response.json())
//                 .then((data) => resolve(data))
//                 .catch((error) => reject(error));
//         });
//     };

//     const getMessages = async () => {

//         try {
//             const response = await fetch(`${baseUrl}/api/messages/${selectedUser?.username}`,
//                 { headers });
//             const data = await response.json();
//             return data;
//         } catch (error) {
//             console.error(error);
//         }

//     };

//     useEffect(() => {
//         getPosts()
//             .then((data) => {
//                 setUsers(data);
//             })
//             .catch((error) => console.log(error));
//         getUsers()
//             .then((data) => {
//                 setUsers(data);
//             })
//             .catch((error) => console.log(error));
//         getUserStatus(userId)
//             .then((status) => {
//                 setIsOnline(status);
//             }).catch((error) => console.log(error));
//         getMessages()
//             .then((data) => {
//                 setMessages(data);
//             })
//             .catch((error) => console.log(error));
//     }, [selectedUser?.username]);

//     /********************* */
//     // const socket = new WebSocket('ws://localhost:4000');.
//     const getUserStatus = (userId) => {

//         // Create the socket instance if it doesn't exist
//         if (!socketRef.current) {
//             socketRef.current = io("ws://localhost:4000");
//             return new Promise((resolve, reject) => {
//                 // Send a request to the server to check the user status
//                 socketRef.current.send(JSON.stringify({ type: 'user_status', userId }));

//                 // Listen for server response
//                 socketRef.current.onmessage = (event) => {
//                     const data = JSON.parse(event.data);

//                     // Check if it's a user status response
//                     if (data.type === 'user_status') {
//                         resolve(data.status);
//                     }
//                 };

//                 // Listen for socket errors
//                 socketRef.current.onerror = (error) => {
//                     reject(error);
//                 };
//             });
//         };
//     }


//     useEffect(() => {

//         if (uiMessagesRef.current) {
//             uiMessagesRef.current.scrollBy({
//                 top: uiMessagesRef.current.scrollHeight,
//                 left: 0,
//                 behavior: "smooth",
//             });
//         }
//         if (socketRef.current) {
//             socketRef.current.on("message", (data) => {
//                 if (selectedUser.username === data.from) {
//                     setMessages([...messages, data]);
//                 } else {
//                     const existUser = users.find((user) => user.username === data.from);
//                     if (existUser) {
//                         setUsers(
//                             users.map((user) =>
//                                 user.username === existUser.username ? { ...user, unread: true } : user
//                             )
//                         );
//                     }
//                 }
//             });
//             socketRef.current.onopen = () => {
//                 console.log('Connected to WebSocket server');
//             };

//             socketRef.current.onclose = () => {
//                 console.log('Disconnected from WebSocket server');
//             };
//             socketRef.current.on("updateUser", (updatedUser) => {
//                 const existUser = users.find((user) => user.username === updatedUser.username);
//                 if (existUser) {
//                     setUsers(
//                         users.map((user) =>
//                             user.username === existUser.username ? updatedUser : user
//                         )
//                     );
//                 } else {
//                     setUsers([...users, updatedUser]);
//                 }
//             });
//             socketRef.current.on("listUsers", (updatedUsers) => {
//                 setUsers(updatedUsers);
//             });

//             socketRef.current.on("selectedUser", (user) => {
//                 setMessages(user.messages);
//             });
//         } else {
//             socketRef.current = io("ws://localhost:4000");
//             // setSocket(sk);
//             socketRef.current.emit("onLogin", {
//                 username: "Admin",
//             });
//         }
//     }, [messages, selectedUser, socketRef.current, users]);

//     // const selectedUser = (user) => {
//     //     setSelectedUser(user);
//     //     const existUser = users.find((x) => x.username === user.username);
//     //     if (existUser) {
//     //         setUsers(
//     //             users.map((x) =>
//     //                 x.username === existUser.username ? { ...x, unread: false } : x
//     //             )
//     //         );
//     //     }
//     //     socketRef.current.emit("onUserSelected", user);
//     // };
//     useEffect(() => {
//         if (selectedUser) {
//             setSelectedUser(user);
//             const existUser = users.find((x) => x.username === user.username);
//             if (existUser) {
//                 setUsers(
//                     users.map((x) =>
//                         x.username === existUser.username ? { ...x, unread: false } : x
//                     )
//                 );
//             }
//             socketRef.current.emit("onUserSelected", user);
//             axios
//                 .get(`${baseUrl}/api/messages/${selectedUser.username}`, { headers })
//                 .then((response) => {
//                     setMessages(response.data);

//                 })
//                 .catch((error) => console.log(error));
//         }
//     }, [selectedUser]);
//     useEffect(() => {
//         getPosts()
//             .then((data) => {
//                 setUsers(data);
//             })
//             .catch((error) => console.log(error));
//         getUsers()
//             .then((data) => {
//                 setUsers(data);
//             })
//             .catch((error) => console.log(error));
//         getUserStatus(userId)
//             .then((status) => {
//                 setIsOnline(status);
//             })
//             .catch((error) => console.log(error));
//         getMessages()
//             .then((data) => {
//                 setMessages(data);
//             })
//             .catch((error) => console.log(error));
//     }, []);
//     const submitHandler = async (e) => {
//         e.preventDefault();
//         if (!content.trim()) {
//             alert("Error. Please type message.");
//         } else {
//             setMessages([
//                 messages,
//                 { content: content, from: "Admin", to: selectedUser.username },
//             ]);
//             setTimeout(() => {
//                 socketRef.current.emit("onMessage", {
//                     content: content,
//                     from: "Admin",
//                     to: selectedUser.username,
//                 });
//             }, 1000);
//             setContent("");
//         }
//         if (!selectedUser) {
//             setError("Recipient is required");
//             return;
//         }

//         if (content.trim() === "") {
//             setError("Message content is required");
//             return;
//         }

//         try {
//             const message = { from: user.username, to: selectedUser.username, content };
//             setContent("");
//             setError("");

//             const res = await axios.post(`${baseUrl}/api/messages`, message);
//             // Send the message to the server
//             //  socket.current.emit("message", message);

//             // You can also update the UI optimistically by adding the message to the local state
//             setMessages((prevMessages) => [...prevMessages, { message: res.data.content, id: res.data.id }]);
//             console.log(res.data.content)
//             socketRef.current.emit("sendMessage", message)
//             notificationAudio.play();
//         } catch (error) {
//             console.log(error);
//             setError("Failed to send message");
//         }
//     };



//     return (
//         <Row>
//             <h2>Admin page </h2>
//             <h6>User :{user?.username}</h6>
//             <h5>selectedUser: {selectedUser.username}</h5>

//             <Col sm={3}>
//                 {users.filter((x) => x.username !== "Admin").length === 0 && (
//                     <Alert variant="info">No User Found</Alert>
//                 )}
//                 <ListGroup>
//                     {users
//                         .filter((x) => x.username !== "Admin")
//                         .map((user) => (
//                             <ListGroup.Item
//                                 action
//                                 key={user.username}
//                                 variant={user.username === selectedUser.username ? "info" : ""}
//                                 // onClick={() => selectedUser(user)}
//                                 onClick={() => setSelectedUser(user)}
//                             >
//                                 <Badge
//                                     bg={
//                                         selectedUser.username === user.username
//                                             ? user.online
//                                                 ? "primary"
//                                                 : "secondary"
//                                             : user.unread
//                                                 ? "danger"
//                                                 : user.online
//                                                     ? "primary"
//                                                     : "secondary"
//                                     }
//                                 >
//                                     {selectedUser.username === user.username
//                                         ? user.online
//                                             ? "Online"
//                                             : "Offline"
//                                         : user.unread
//                                             ? "New"
//                                             : user.online
//                                                 ? "Online"
//                                                 : "Offline"}
//                                 </Badge>
//                                 &nbsp;
//                                 {user.username}
//                             </ListGroup.Item>
//                         ))}
//                 </ListGroup>
//             </Col>
//             <Col sm={9}>
//                 <div className="admin">
//                     {!selectedUser.username ? (
//                         <Alert variant="info">Select a user to start chat</Alert>
//                     ) : (
//                         <div>
//                             <h2>Chat with {selectedUser.username}</h2>
//                             {console.log(messages)}
//                             <h1>User Profile</h1>
//                             <p>Status: {isOnline ? 'Online' : 'Disconnected'}</p>
//                             <ListGroup ref={uiMessagesRef}>
//                                 {messages?.length === 0 && (
//                                     <ListGroup.Item>No message</ListGroup.Item>
//                                 )}
//                                 {messages[0]?.map((msg, index) => (
//                                     <ListGroup.Item key={index}>
//                                         <strong>{`${msg.from}: `}</strong> {msg.content}
//                                     </ListGroup.Item>
//                                 ))}
//                             </ListGroup>
//                             <div>
//                                 <form onSubmit={submitHandler}>
//                                     <InputGroup className="col-6">
//                                         <FormControl
//                                             value={content}
//                                             onChange={(e) => setContent(e.target.value)}
//                                             type="text"
//                                             placeholder="type message"
//                                         ></FormControl>
//                                         <Button type="submit" variant="primary">
//                                             Send
//                                         </Button>
//                                     </InputGroup>
//                                 </form>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             </Col>
//         </Row>
//     );
// }

// import React, { useState, useEffect, useRef } from 'react';
// import { Row, Col, ListGroup, Badge, Alert, InputGroup, FormControl, Button } from 'react-bootstrap';
// import io from ' socket.current.io-client';
// import axios from "axios";
// import classNames from "classnames";
// import "./chatadmin.css"
// import { baseUrl } from "../../App";
// import Message from '../../components/message/Message';
// import { useAuthDispatch } from './../../context/auth';
// import { useMessageDispatch } from './../../context/message';
// const Chat = (message, own) => {
//     const [users, setUsers] = useState([]);
//     const [selectedUser, setSelectedUser] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [content, setContent] = useState('');
//     const [error, setError] = useState('');
//     const [isOnline, setIsOnline] = useState(false);
//     const [onlineUsers, setOnlineUsers] = useState([]);
//     const uiMessagesRef = useRef(null);
//     const socketRef = useRef(null);
//     const user = JSON.parse(localStorage.getItem("user"));
//     const token = JSON.parse(localStorage.getItem("token"));
//     const authDispatch = useAuthDispatch();
//     const messageDispatch = useMessageDispatch();
//     const [formattedMessages, setFormattedMessages] = useState([]);
//     const sent = message && message.from === user.username;
//     const received = message && !sent;
//     const headers = {
//         "Accept": "application/json",
//         "Authorization": `Bearer ${token}`
//     };
//     const queryParams = { userId: user.id }
//     // Assuming you have a list of connected users with their status
//     const connectedUsers = [
//         { id: user.id, username: user.username, isOnline: isOnline },
//         // { id: 25, username: 'user2', isOnline: false },

//         // Add more users...
//     ];
//     socketRef.current = io('ws://localhost:4000', {
//         upgrade: false,
//         query: queryParams,
//         reconnection: false,
//         rejectUnauthorized: false
//     });
//     useEffect(() => {


//         socketRef.current.emit("addUser", user.userId);

//         socketRef.current.on("getUsers", (users) => {
//             //members .filter((f) => users.some((u) => u.userId === f))
//             setOnlineUsers(users);
//         });
//     }, [user]);
//     // Function to update a user's status
//     const updateUserStatus = (userId, isOnline) => {


//         socketRef.current.once('connect', () => {
//             console.log('Connected to socket');
//         });
//         // USER IS ONLINE

//         socketRef.current.on("online", (userId) => {
//             const user = connectedUsers.find((user) => user.id === userId);
//             if (user) {
//                 user.isOnline = isOnline;
//                 // Emit the user_status event to inform clients about the status change
//                 socketRef.current.emit('user_status', { userId, isOnline });
//             }
//             console.log(isOnline, "Is Online!"); // update online status
//         });

//         // USER IS OFFLINE
//         socketRef.current.on("offline", (userId) => {
//             console.log(userId, "Is Offline!"); // update offline status
//         });

//         socketRef.current.on('disconnect', () => {
//             console.log('Disconnected from socket');
//         });

//     };

//     // const userId = user.id
//     // Example code to simulate a status change for a user
//     // setTimeout(() => {
//     //     updateUserStatus(userId, true); // Simulate user2 going online
//     // }, 5000);
//     const [userStatus, setUserStatus] = useState({});


//     useEffect(() => {
//         console.log('User status:', userStatus);
//     }, []);
//     // Add more code to handle socket connections and events...

//     useEffect(() => {
//         getUsers()
//             .then((data) => {
//                 setUsers(data);
//             })
//             .catch((error) => console.log(error));
//     }, []);
//     const NEW_MESSAGE = {};

//     // here we are aliasing the data and error variables
//     const { data: messageData, error: messageError } = NEW_MESSAGE;
//     useEffect(() => {
//         const newMessages = messages?.filter(
//             (message) => message?.to === user.username || message?.from === user.username
//         );
//         const otherUsers = newMessages?.map((message) =>
//             message.to === user.username ? message.from : message.to
//         );
//         const uniqueOtherUsers = [...new Set(otherUsers)];

//         const formattedMessages = uniqueOtherUsers?.map((username) => {
//             const messagesWithUser = newMessages.filter(
//                 (message) => message.to === username || message.from === username
//             );
//             const lastMessage =
//                 messagesWithUser[messagesWithUser.length - 1]?.content;
//             const formattedMessage = {
//                 username,
//                 message: {
//                     content: lastMessage,
//                     createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
//                 },
//             };
//             console.log(formattedMessage)
//             return formattedMessage;
//         });

//         // Sort the formatted messages by the latest message createdAt timestamp
//         const sortedMessages = formattedMessages.sort((a, b) => {
//             const aTimestamp = a.message.createdAt || 0;
//             const bTimestamp = b.message.createdAt || 0;
//             return bTimestamp - aTimestamp;
//         });

//         setFormattedMessages(sortedMessages);
//         setFormattedMessages(formattedMessages);
//     }, [messages]);
//     const getUsers = async () => {
//         try {
//             const response = await axios.get(`${baseUrl}/api/users/`, { headers });
//             return response.data;
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         if (messageError) console.log(messageError);

//         if (messageData) {
//             const message = messageData.newMessage;
//             const otherUser =
//                 user.username === message.to ? message.from : message.to;

//             messageDispatch({
//                 type: "ADD_MESSAGE",
//                 payload: {
//                     username: otherUser,
//                     message,
//                 },
//             });
//         }
//     }, []);

//     useEffect(() => {
//         if (selectedUser) {
//             axios
//                 .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
//                 .then((response) => {
//                     setMessages(response.data);

//                 })
//                 .catch((error) => console.log(error));
//         }
//         // socketRef.current = io('http://localhost:4000'); // Replace with your socket server URL


//         socketRef.current.on('user_list', (users) => {
//             setUsers(users);
//         });

//         socketRef.current.on('new_message', (message) => {
//             if (selectedUser && selectedUser.username === message.to) {
//                 setMessages((messages) => [...messages, message]);
//                 uiMessagesRef.current.scrollBy({
//                     top: uiMessagesRef.current.scrollHeight,
//                     left: 0,
//                     behavior: 'smooth',
//                 });
//             } else {
//                 // Play notification audio or show a notification
//             }
//         });

//         socketRef.current.on('user_status', (status) => {
//             setIsOnline(status);
//         });
//         socketRef.current.on('user_status', ({ userId, isOnline }) => {
//             // Update the user status in the state
//             setUserStatus((prevStatus) => ({
//                 ...prevStatus,
//                 [userId]: isOnline,
//             }));
//         });
//         return () => {
//             if (socketRef.current) {
//                 socketRef.current.disconnect();
//             }
//         };
//     }, [selectedUser]);

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (content === '') {
//             setError('Please enter a message');
//             return;
//         }
//         if (!selectedUser) {
//             setError("Recipient is required");
//             return;
//         }
//         socketRef.current.emit('new_message', {
//             content,
//             from: user.username,
//             to: selectedUser.username,
//         });
//         try {
//             const message = { from: user.username, to: selectedUser.username, content };
//             socketRef.current.send(JSON.stringify(message));
//             setContent("");
//             setError("");
//             const res = axios.post(`${baseUrl}/api/messages`, message);
//             setMessages((prevMessages) => [...prevMessages, res.data]);
//         } catch (error) {
//             console.log(error);
//             setError("Failed to send message");
//         }
//     };

//     const handleUserClick = (user) => {

//         setSelectedUser(user);
//         setMessages([]); // Clear the messages of the previously selected user
//     };

//     return (
//         <Row className="bg-white justify-content-center rounded-pill shadow-lg ml-0 mr-0 chat-container">

//             <Col xs={8} className="p-0">
//                 <div
//                     ref={uiMessagesRef}
//                     className={own ? " message own" : "message"}
//                     style={{ minHeight: '300px', maxHeight: '300px', overflowY: 'auto' }}
//                 >
//                     <Message message={message} />

//                     {messages.map((message, index) => (
//                         <p key={index} className={classNames("py-2 px-3 rounded-pill", {
//                             "bg-primary": sent,
//                             "bg-secondary": received,
//                         })}>
//                             {message?.content}
//                         </p>

//                     ))}
//                 </div>

//             </Col>
//             <Row className="bg-white justify-content-center rounded-pill shadow-lg ml-0 mr-0 chat-container">
//                 <Col xs={4} className="px-0">
//                     <h5 className="mb-3">online</h5>
//                     {connectedUsers.map((user) => (
//                         <ListGroup.Item
//                             key={user.id}
//                             className={`rounded-pill text-info ${selectedUser?.username === user.username ? 'bg-primary' : ''}`}
//                             onClick={() => handleUserClick(user)}
//                             role="button"
//                         >
//                             <h6>{user.username}</h6>
//                             {user.username === selectedUser?.username && (
//                                 <Badge variant={user.isOnline ? 'success' : 'danger'}>
//                                     {user.isOnline ? 'Online' : 'Offline'}
//                                 </Badge>
//                             )}
//                             {/* ...other code... */}
//                         </ListGroup.Item>
//                     ))}
//                     <h5 className="mb-3">Friends</h5>
//                     <ListGroup variant="flush" className="mb-2 user-list">
//                         {users?.map((user) => (
//                             <ListGroup.Item
//                                 key={user.id}
//                                 // className={`user ${selectedUser?.username === user.username ? "selected" : ""}`}
//                                 className={`rounded-pill text-info ${selectedUser?.username === user.username ? 'bg-primary' : ''
//                                     }`}
//                                 onClick={() => setSelectedUser(user)}
//                                 role="button"
//                             >
//                                 <h6>{user.username}</h6>
//                                 {user.username === selectedUser?.username && (
//                                     <Badge variant={isOnline ? 'success' : 'danger'}>
//                                         {isOnline ? 'Online' : 'Offline'}
//                                     </Badge>
//                                 )}
//                                 {formattedMessages[0]?.username === user.username ? (
//                                     <>
//                                         <span className="content">{formattedMessages[0]?.message.content}</span>
//                                     </>
//                                 ) : (
//                                     <span className="no-message">.. <Badge variant={isOnline ? 'success' : 'danger'}>
//                                         {isOnline ? 'Online' : 'Offline'}
//                                     </Badge> </span>
//                                 )}
//                                 <div className="user-info">



//                                 </div>
//                             </ListGroup.Item>
//                         ))}
//                     </ListGroup>
//                     {error && <Alert variant="danger">{error}</Alert>}
//                     <InputGroup className="my-3 d-flex align-items-center">
//                         <FormControl
//                             className="message-input p-2 rounded-pill bg-secondary border-0"
//                             as="textarea"
//                             placeholder="Enter your message..."
//                             value={content}
//                             onChange={(e) => setContent(e.target.value)}
//                         />
//                         <InputGroup.Append>
//                             <Button className="fas fa-paper-plane fa-2x text-primary mx-2" variant="info" onClick={handleSubmit}>
//                                 Send2
//                             </Button>
//                         </InputGroup.Append>
//                     </InputGroup>
//                 </Col>
//                 <Col xs={8} className="p-0">
//                     <div
//                         ref={uiMessagesRef}
//                         className={own ? " message own" : "message"}
//                         style={{ minHeight: '300px', maxHeight: '300px', overflowY: 'auto' }}
//                     >
//                         <Message message={message} />


//                     </div>

//                 </Col>
//             </Row>
//         </Row>
//     );
// };

// export default Chat;
// import React, { useState, useEffect, useRef } from 'react';
// import { Row, Col, ListGroup, Badge, Alert, InputGroup, FormControl, Button } from 'react-bootstrap';
// import io from ' socket.current.io-client';
// import axios from 'axios';
// import classNames from 'classnames';
// import "./chatadmin.css";
// import { baseUrl } from "../../App";
// import Message from '../../components/message/Message';
// import { useAuthDispatch } from './../../context/auth';
// import { useMessageDispatch } from './../../context/message';

// const Chat = () => {
//     const [users, setUsers] = useState([]);
//     const [selectedUser, setSelectedUser] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [content, setContent] = useState('');
//     const [error, setError] = useState('');
//     const [isOnline, setIsOnline] = useState(false);
//     const [onlineUsers, setOnlineUsers] = useState([]);
//     const uiMessagesRef = useRef(null);
//     const socketRef = useRef(null);
//     const user = JSON.parse(localStorage.getItem("user"));
//     const token = JSON.parse(localStorage.getItem("token"));
//     const authDispatch = useAuthDispatch();
//     const messageDispatch = useMessageDispatch();
//     const [formattedMessages, setFormattedMessages] = useState([]);
//     const [userStatus, setUserStatus] = useState({});
//     const headers = {
//         "Accept": "application/json",
//         "Authorization": `Bearer ${token}`
//     };
//     const queryParams = { userId: user.id };

//     useEffect(() => {
//         socketRef.current = io('ws://localhost:4000', {
//             upgrade: false,
//             query: queryParams,
//             reconnection: false,
//             rejectUnauthorized: false
//         });

//         socketRef.current.on("getUsers", (users) => {
//             setOnlineUsers(users);
//         });

//         return () => {
//             if (socketRef.current) {
//                 socketRef.current.disconnect();
//             }
//         };
//     }, [queryParams]);

//     useEffect(() => {
//         getUsers()
//             .then((data) => {
//                 setUsers(data);
//             })
//             .catch((error) => console.log(error));
//     }, []);

//     useEffect(() => {
//         if (selectedUser) {
//             axios
//                 .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
//                 .then((response) => {
//                     setMessages(response.data);
//                 })
//                 .catch((error) => console.log(error));
//         }

//         socketRef.current.on('new_message', (message) => {
//             if (selectedUser && selectedUser.username === message.to) {
//                 setMessages((messages) => [...messages, message]);
//                 uiMessagesRef.current.scrollBy({
//                     top: uiMessagesRef.current.scrollHeight,
//                     left: 0,
//                     behavior: 'smooth',
//                 });
//             } else {
//                 // Play notification audio or show a notification
//             }
//         });

//         socketRef.current.on('user_status', (status) => {
//             setIsOnline(status);
//             console.log(status)

//         });

//         socketRef.current.on('user_status', ({ userId, isOnline }) => {
//             // Update the user status in the state
//             setUserStatus((prevStatus) => ({
//                 ...prevStatus,
//                 [userId]: isOnline,
//             }));


//         });
//         console.log(socketRef.current);
//         return () => {
//             if (socketRef.current) {
//                 socketRef.current.disconnect();
//             }
//         };
//     }, [selectedUser]);

//     useEffect(() => {
//         const newMessages = messages?.filter(
//             (message) => message?.to === user.username || message?.from === user.username
//         );
//         const otherUsers = newMessages?.map((message) =>
//             message.to === user.username ? message.from : message.to
//         );
//         const formattedMessages = newMessages?.reduce((acc, message) => {
//             const messageDate = new Date(message.createdAt);
//             const formattedDate = messageDate.toLocaleString('en-US', {
//                 hour: 'numeric',
//                 minute: 'numeric',
//                 hour12: true,
//             });

//             if (!acc[message.from]) {
//                 acc[message.from] = [];
//             }

//             acc[message.from].push({
//                 ...message,
//                 formattedDate,
//             });

//             return acc;
//         }, {});

//         setFormattedMessages(formattedMessages);

//         if (selectedUser && !otherUsers?.includes(selectedUser.username)) {
//             setSelectedUser(null);
//         }
//     }, [messages, selectedUser]);

//     const getUsers = async () => {
//         try {
//             const response = await axios.get(`${baseUrl}/api/users`, { headers });
//             return response.data;
//         } catch (error) {
//             throw error;
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         if (content.trim() === '') {
//             setError('Message cannot be empty');
//             return;
//         }

//         const message = {
//             content,
//             to: selectedUser.username,
//         };

//         socketRef.current.emit('new_message', message);
//         setMessages((messages) => [...messages, message]);
//         setContent('');
//         setError('');
//         uiMessagesRef.current.scrollBy({
//             top: uiMessagesRef.current.scrollHeight,
//             left: 0,
//             behavior: 'smooth',
//         });
//     };

//     const handleUserClick = (user) => {
//         setSelectedUser(user);
//         setError('');
//     };

//     return (
//         <div className="chat">
//             <Row className="no-gutters">
//                 <Col xs={4} className="user-list">
//                     <ListGroup variant="flush">
//                         {users.map((user) => (
//                             <ListGroup.Item
//                                 key={user.username}
//                                 onClick={() => handleUserClick(user)}
//                                 action
//                                 variant={user.username === selectedUser?.username ? 'primary' : ''}
//                             >
//                                 {user.username}
//                                 <Badge
//                                     pill
//                                     className={classNames('float-right', {
//                                         'bg-success': onlineUsers.includes(user.username),
//                                         'bg-secondary': !onlineUsers.includes(user.username),
//                                     })}
//                                 ></Badge>
//                             </ListGroup.Item>
//                         ))}
//                     </ListGroup>
//                 </Col>
//                 <Col xs={8} className="message-list">
//                     {selectedUser ? (
//                         <>
//                             <div className="message-header">
//                                 <h5>
//                                     {selectedUser.username}
//                                     {isOnline ? (
//                                         <Badge pill variant="success" className="ml-2">
//                                             Online
//                                         </Badge>
//                                     ) : (
//                                         <Badge pill variant="secondary" className="ml-2">
//                                             Offline
//                                         </Badge>
//                                     )}
//                                 </h5>
//                             </div>
//                             <div className="messages" ref={uiMessagesRef}>
//                                 {formattedMessages[selectedUser.username] ? (
//                                     formattedMessages[selectedUser.username].map((message, index) => (
//                                         <Message key={index} message={message} user={user} />
//                                     ))
//                                 ) : (
//                                         <p>No messages yet</p>
//                                 )}
//                             </div>
//                             <div className="message-input">
//                                 <form onSubmit={handleSubmit}>
//                                     <InputGroup>
//                                         <FormControl
//                                             as="textarea"
//                                             value={content}
//                                             onChange={(e) => setContent(e.target.value)}
//                                             placeholder="Type a message..."
//                                             className={error && 'is-invalid'}
//                                         />
//                                         <InputGroup.Append>
//                                             <Button type="submit">Send</Button>
//                                         </InputGroup.Append>
//                                     </InputGroup>
//                                     {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
//                                 </form>
//                             </div>
//                         </>
//                     ) : (
//                         <div className="message-info">
//                             <p>Select a user to start a conversation</p>
//                         </div>
//                     )}
//                 </Col>
//             </Row>
//         </div>
//     );
// };

// export default Chat;
// import React, { useState, useEffect, useRef } from 'react';
// import { Row, Col, ListGroup, Badge, Alert, InputGroup, FormControl, Button } from 'react-bootstrap';
// import io from ' socket.current.io-client';
// import axios from 'axios';
// import classNames from 'classnames';
// import "./chatadmin.css";
// import { baseUrl } from "../../App";
// import Message from '../../components/message/Message';
// import { useAuthDispatch } from './../../context/auth';
// import { useMessageDispatch } from './../../context/message';

// const Chat = () => {

//     const [users, setUsers] = useState([]);
//     const [selectedUser, setSelectedUser] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [content, setContent] = useState('');
//     const [error, setError] = useState('');
//     const [isOnline, setIsOnline] = useState(false);
//     const [onlineUsers, setOnlineUsers] = useState([]);
//     const uiMessagesRef = useRef(null);
//     const socketRef = useRef(null);
//     const user = JSON.parse(localStorage.getItem("user"));
//     const token = JSON.parse(localStorage.getItem("token"));
//     const authDispatch = useAuthDispatch();
//     const messageDispatch = useMessageDispatch();
//     const [formattedMessages, setFormattedMessages] = useState([]);
//     const [userStatus, setUserStatus] = useState({});
//     const headers = {
//         "Accept": "application/json",
//         "Authorization": `Bearer ${token}`
//     };
//     const queryParams = { userId: user.id };

//     useEffect(() => {
//         socketRef.current = io('ws://localhost:4000', {
//             // upgrade: false,
//             query: queryParams,
//             // reconnection: false,
//             // rejectUnauthorized: false
//         });

//         socketRef.current.on("getUsers", (users) => {
//             setOnlineUsers(users);
//         });

//         socketRef.current.on('user_status', ({ userId, isOnline }) => {
//             // Update the user status in the state
//             setUserStatus((prevStatus) => ({
//                 ...prevStatus,
//                 [userId]: isOnline,
//             }));
//         });

//         // Emit user_online event to inform server that the user is online
//         socketRef.current.emit('user_online', { userId: user.id });

//         return () => {
//             if (socketRef.current) {
//                 socketRef.current.disconnect();
//             }
//         };
//     }, [queryParams]);

//     useEffect(() => {
//         getUsers()
//             .then((data) => {
//                 setUsers(data);
//             })
//             .catch((error) => console.log(error));
//     }, []);

//     useEffect(() => {
//         if (selectedUser) {
//             axios
//                 .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
//                 .then((response) => {
//                     setMessages(response.data);
//                 })
//                 .catch((error) => console.log(error));
//         }

//         socketRef.current.on('sendMessage', (message) => {
//             if (selectedUser && selectedUser.username === message.to) {
//                 setMessages((messages) => [...messages, message]);
//                 uiMessagesRef.current.scrollBy({
//                     top: uiMessagesRef.current.scrollHeight,
//                     left: 0,
//                     behavior: 'smooth',
//                 });
//             } else {
//                 // Play notification audio or show a notification
//             }
//         });

//         console.log(user);
//         return () => {
//             if (socketRef.current) {
//                 socketRef.current.disconnect();
//             }
//         };
//     }, [selectedUser]);

//     useEffect(() => {
//         const newMessages = messages?.filter(
//             (message) => message?.to === user.username || message?.from === user.username
//         );
//         const otherUsers = newMessages?.map((message) =>
//             message.to === user.username ? message.from : message.to
//         );
//         const formattedMessages = newMessages?.reduce((acc, message) => {
//             const messageDate = new Date(message.createdAt);
//             const formattedDate = messageDate.toLocaleString('en-US', {
//                 hour: 'numeric',
//                 minute: 'numeric',
//                 hour12: true,
//             });

//             if (!acc[message.from]) {
//                 acc[message.from] = [];
//             }

//             acc[message.from].push({
//                 ...message,
//                 formattedDate,
//             });

//             return acc;
//         }, {});

//         setFormattedMessages(formattedMessages);

//         if (selectedUser && !otherUsers?.includes(selectedUser.username)) {
//             setSelectedUser(null);
//         }
//     }, [messages, selectedUser]);

//     const getUsers = async () => {
//         try {
//             const response = await axios.get(`${baseUrl}/api/users`, { headers });
//             return response.data;
//         } catch (error) {
//             throw error;
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         if (content.trim() === '') {
//             setError('Message cannot be empty');
//             return;
//         }

//         const message = {
//             content,
//             to: selectedUser.username,
//         };

//         socketRef.current.emit('new_message', message);
//         setMessages((messages) => [...messages, message]);
//         setContent('');
//         setError('');
//         uiMessagesRef.current.scrollBy({
//             top: uiMessagesRef.current.scrollHeight,
//             left: 0,
//             behavior: 'smooth',
//         });
//     };

//     const handleUserClick = (user) => {
//         setSelectedUser(user);
//         setError('');
//     };

//     return (
//         <div className="chat">
//             <Row className="no-gutters">
//                 <Col xs={4} className="user-list">
//                     <ListGroup variant="flush">
//                         {users.map((user) => (
//                             <ListGroup.Item
//                                 key={user.username}
//                                 onClick={() => handleUserClick(user)}
//                                 action
//                                 variant={user.username === selectedUser?.username ? 'primary' : ''}
//                             >
//                                 {user.username}
//                                 <Badge
//                                     pill
//                                     className={classNames('float-right', {
//                                         'bg-success': userStatus[user.id],
//                                         'bg-secondary': !userStatus[user.id],
//                                     })}
//                                 ></Badge>
//                             </ListGroup.Item>
//                         ))}
//                     </ListGroup>
//                 </Col>
//                 <Col xs={8} className="message-list">
//                     {selectedUser ? (
//                         <>
//                             <div className="message-header">
//                                 <h5>
//                                     {selectedUser.username}
//                                     {userStatus[selectedUser.id] ? (
//                                         <Badge pill variant="success" className="ml-2">
//                                             Online
//                                         </Badge>
//                                     ) : (
//                                         <Badge pill variant="secondary" className="ml-2">
//                                             Offline
//                                         </Badge>
//                                     )}
//                                 </h5>
//                             </div>
//                             <div className="messages" ref={uiMessagesRef}>
//                                 {formattedMessages[selectedUser.username] ? (
//                                     formattedMessages[selectedUser.username].map((message, index) => (
//                                         <Message key={index} message={message} user={user} />
//                                     ))
//                                 ) : (
//                                         <p>No messages yet</p>
//                                 )}
//                             </div>
//                             <div className="message-input">
//                                 <form onSubmit={handleSubmit}>
//                                     <InputGroup>
//                                         <FormControl
//                                             as="textarea"
//                                             value={content}
//                                             onChange={(e) => setContent(e.target.value)}
//                                             placeholder="Type a message..."
//                                             className={error && 'is-invalid'}
//                                         />
//                                         <InputGroup.Append>
//                                             <Button type="submit">Send</Button>
//                                         </InputGroup.Append>
//                                     </InputGroup>
//                                     {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
//                                 </form>
//                             </div>
//                         </>
//                     ) : (
//                         <div className="message-info">
//                             <p>Select a user to start a conversation</p>
//                         </div>
//                     )}
//                 </Col>
//             </Row>
//         </div>
//     );
// };

// export default Chat;
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, ListGroup, Badge, Alert, InputGroup, FormControl, Button } from 'react-bootstrap';
import { io } from "socket.io-client";
import axios from 'axios';
import Message from '../../components/message/Message';
import classNames from 'classnames';
import "./chatadmin.css";

import { useAuthDispatch } from './../../context/auth';
import { useMessageDispatch } from './../../context/message';
import { baseUrl } from './../../Provider';
import ChatOnline from './../../components/chatOnline/ChatOnline';
import CloseFriend from './../../components/closeFriend/CloseFriend';
const Chat = () => {
    const [isOnline, setIsOnline] = useState(false);
    const uiMessagesRef = useRef(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [text, setText] = useState('');
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [queryParams, setQueryParams] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    // const socketRef = useRef(null);
    const authDispatch = useAuthDispatch();
    const messageDispatch = useMessageDispatch();
    const user = JSON.parse(localStorage.getItem("user"));
    const token = (localStorage.getItem("token"));
    const [content, setContent] = useState('');
    const [formattedMessages, setFormattedMessages] = useState({});
    const [userStatus, setUserStatus] = useState({});
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [currentChat, setCurrentChat] = useState("");
    const PF = `${baseUrl}/images/`;
    const headers = {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
    };
    const socket = useRef();
    useEffect(() => {
        socket.current = io(baseUrl);

        socket.current.on('connect', () => {
            console.log('Connected to socket server');
        });

        socket.current.on('login', (userId) => {
            messageDispatch({
                type: 'login',
                payload: userId,
            });
        });
        socket.current.on('user_status', (status) => {
            setIsOnline(status);

        });

        socket.current.on('user_status', ({ userId, isOnline }) => {
            // Update the user status in the state
            setUserStatus((prevStatus) => ({
                ...prevStatus,
                [userId]: isOnline,
            }));

            console.log(userId);
        });
        //  socket.current.on('disconnect', () => {
        //     console.log('Disconnected from socket server');
        // });

        socket.current.on('login', (message) => {
            messageDispatch({
                type: 'ADD_MESSAGE',
                payload: message,
            });
        });

        socket.current.on('typing', (userId) => {
            if (userId === selectedUser?.id) {
                setIsTyping(true);
            }
        });

        socket.current.on('stop_typing', (userId) => {
            if (userId === selectedUser?.id) {
                setIsTyping(false);
            }
        });



        // Cleanup function to disconnect and remove event listeners
        return () => {
            socket.current.disconnect();
        };
    }, []);

    useEffect(() => {
        getUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => console.log(error));
    }, []);

    // useEffect(() => {
    //      socket.current.emit("addUser", user.id);
    //      socket.current.on("getUsers", (users) => {
    //         const updatedUsers = users.map((user) => ({
    //             ...user,
    //             // online: Boolean(io.sockets.sockets.get(user.socketId)),
    //             // online: Boolean(io.sockets.connected[user.socketId]),
    //             online: Boolean(socket.current.connected),
    //         }));
    //          setOnlineUsers(updatedUsers);
    //         //  setOnlineUsers(onlineUsers);
    //          console.log(updatedUsers)
    //     });

    //     //  socket.current.current.on("getUsers", (users) => {
    //     //   //members .filter((f) => users.some((u) => u.userId === f))
    //     //   setOnlineUsers(users);
    //     // });
    // }, []);
    // useEffect(() => {
    //     socket.current.emit("addUser", user.id);
    //     socket.current.on("getUsers", (users) => {
    //         const updatedUsers = users.map((user) => ({
    //             ...user,
    //             online: socket.current.connected && user.socketId === socket.current.id,
    //         }));
    //         setOnlineUsers(updatedUsers);

    //     });
    // }, []);
    // Define a separate function to update online users
    const updateOnlineUsers = (users) => {
        const updatedUsers = users.map((user) => ({
            ...user,
            online: socket.current.connected && user.socketId === socket.current.id,
        }));
        setOnlineUsers(updatedUsers);
    };

    // Inside the component
    useEffect(() => {
        const handleGetUsers = (users) => {
            updateOnlineUsers(users);
        };

        socket.current.emit("addUser", user.id);
        socket.current.on("getUsers", handleGetUsers);

        // Cleanup the event listener when the component unmounts
        return () => {
            socket.current.off("getUsers", handleGetUsers);
        };
    }, []);

    // handleGetUsers(users)
    useEffect(() => {
        if (selectedUser) {
            axios
                .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
                .then((response) => {
                    setMessages(response.data);
                })
                .catch((error) => console.log(error));
        }
    }, [selectedUser]);
    useEffect(() => {
        const socket = io(baseUrl);
        if (selectedUser) {
            axios
                .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
                .then((response) => {
                    setMessages(response.data);
                })
                .catch((error) => console.log(error));
        }
        socket.current = io(baseUrl);
        socket.current.on('sendMessage', (message) => {
            if (selectedUser && selectedUser.username === message.to) {
                setMessages((messages) => [...messages, message]);
                uiMessagesRef.current.scrollBy({
                    top: uiMessagesRef.current.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Play notification audio or show a notification
            }
        });





        return () => {
            if (socket) {
                socket.current.disconnect();
            }
        };
    }, [selectedUser]);



    useEffect(() => {
        const newMessages = messages?.filter(
            (message) => message?.to === user.username || message?.from === user.username
        );
        const otherUsers = newMessages?.map((message) =>
            message.to === user.username ? message.from : message.to
        );
        const formattedMessages = newMessages?.reduce((acc, message) => {
            const messageDate = new Date(message.createdAt);
            const formattedDate = messageDate.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });

            if (!acc[message.from]) {
                acc[message.from] = [];
            }

            acc[message.from].push({
                ...message,
                formattedDate,
            });

            return acc;
        }, {});

        setFormattedMessages(formattedMessages);


        if (selectedUser && !otherUsers?.includes(selectedUser.username)) {
            setSelectedUser(null);
        }
    }, [messages, user.username, user.id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (content.trim() === '') {
            setError('Message cannot be empty');
            return;
        }

        const message = { from: user.username, to: selectedUser.username, content };
        socket.current.send(JSON.stringify(message));
        socket.current.emit('sendMessage', message, user);
        // setMessages((messages) => [...messages, message]);
        setContent('');
        setError('');
        const res = axios.post(`${baseUrl}/api/messages`, message);
        setMessages((prevMessages) => [...prevMessages, res.data]);
        uiMessagesRef.current.scrollBy({
            top: uiMessagesRef.current.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    };/******/
    const handleUserClick = (user) => {
        setSelectedUser(user);
        setError('');
    };
    /********** */
    const getUsers = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/users`, { headers });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const handleTyping = () => {
        if (socket.current) {
            socket.current.emit('typing', selectedUser?.id);
        }
    };

    const handleStopTyping = () => {
        if (socket.current) {
            socket.current.emit('stop_typing', selectedUser?.id);
        }
    };


    console.log(formattedMessages)

    return (
        <div className="chat">
            <Row className="chat-container">




                {/* <div className="chatOnline">
                    <div className="chatOnlineWrapper">
                        <ChatOnline
                            onlineUsers={onlineUsers}
                            currentId={user.id}
                            setCurrentChat={setCurrentChat}
                        />
                    </div>
                    <CloseFriend />
                </div> */}
                <Col xs={4} className="user-list">

                    {/* <div className="chatOnline">
                        {users.map((user) => (

                            // onClick = {() => handleClick(user)}
                            <div
                                key={user.id}
                                action
                                onClick={() => handleUserClick(user)}
                                className={classNames({ active: selectedUser && selectedUser.id === user.id })}>
                                <div className="chatOnlineImgContainer">
                                    <img
                                        className="chatOnlineImg"
                                        src={user?.profilePicture ? PF + user.profilePicture : PF + "user.png"}
                                        alt=""
                                    />
                                    <span>{user.username} :..:</span>
                                    <div
                                        className={`chatOnlineBadge ${onlineUsers[0]?.online && onlineUsers[0]?.userId === user.id ? "online" : "offline"
                                            }`}
                                    ></div>
                                </div>

                            </div>
                        ))}
                    </div> */}
                    <ListGroup>
                        {/* Display list of users */}
                        {users.map((user) => (
                            <ListGroup.Item
                                key={user.id}
                                action
                                onClick={() => handleUserClick(user)}
                                className={classNames({ active: selectedUser && selectedUser.id === user.id })}
                            >
                                <img
                                    className="chatOnlineImg"
                                    src={user?.profilePicture ? PF + user.profilePicture : PF + "user.png"}
                                    alt=""
                                />

                                <div
                                    className={`chatOnlineBadge ${onlineUsers[0]?.online && onlineUsers[0]?.userId === user.id ? "online" : "offline"
                                        }`}
                                ></div>
                                <span>{user.username}</span>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col xs={8} className="message-section">
                    <div className="message-list" ref={uiMessagesRef}>
                        {/* Display list of messages */}
                        {/* {messages?.map((message, index) => (
                            <div key={message?.id}>
                                <Message message={message} />
                                {index === messages.length - 1 && (
                                    <div >
                                        <hr className="m-0" />
                                    </div>
                                )}
                            </div>
                        ))} */}
                        {formattedMessages[user?.username]?.map((message) => (
                            <Message key={message.id} message={message} />
                        ))}
                    </div>
                    <div className="message-input">
                        {/* Input field for typing message */}
                        <InputGroup onSubmit={handleSubmit}>
                            <FormControl
                                className="message-input p-2 rounded-pill bg-secondary border-1"
                                placeholder="Type your message..."
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                onKeyDown={handleTyping}
                                onKeyUp={handleStopTyping}
                            />
                            <InputGroup.Append>
                                {/* <Button className="fas fa-paper-plane fa-2x text-primary mx-2"type="submit" variant="primary" onClick={handleSubmit}>
                                    Send
                                </Button> */}
                                <i
                                    className="fas fa-paper-plane fa-2x text-primary mx-2"
                                    onClick={handleSubmit}
                                    role="button"
                                ></i>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Chat;


// const Chat = () => {
//     const [isOnline, setIsOnline] = useState(false);
//     const uiMessagesRef = useRef(null);
//     const [selectedUser, setSelectedUser] = useState(null);
//     const [text, setText] = useState('');
//     const [messages, setMessages] = useState([]);
//     const [users, setUsers] = useState([]);
//     const [queryParams, setQueryParams] = useState({});
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [isTyping, setIsTyping] = useState(false);
//     const socketRef = useRef(null);
//     const authDispatch = useAuthDispatch();
//     const messageDispatch = useMessageDispatch();
//     const user = JSON.parse(localStorage.getItem("user"));
//     const token = JSON.parse(localStorage.getItem("token"));
//     const [content, setContent] = useState('');
//     const [formattedMessages, setFormattedMessages] = useState([]);
//     const [userStatus, setUserStatus] = useState({});
//     const headers = {
//         "Accept": "application/json",
//         "Authorization": `Bearer ${token}`
//     };

//     useEffect(() => {
//         const socket = io(baseUrl);

//          socket.current.on('connect', () => {
//             console.log('Connected to socket server');
//         });

//          socket.current.on('user_status', (status) => {
//             setIsOnline(status);
//             console.log(status);
//         });

//          socket.current.on('disconnect', () => {
//             console.log('Disconnected from socket server');
//         });

//          socket.current.on('new_message', (message) => {
//             messageDispatch({
//                 type: 'ADD_MESSAGE',
//                 payload: message,
//             });
//         });

//          socket.current.on('typing', (userId) => {
//             if (userId === selectedUser?.id) {
//                 setIsTyping(true);
//             }
//         });

//          socket.current.on('stop_typing', (userId) => {
//             if (userId === selectedUser?.id) {
//                 setIsTyping(false);
//             }
//         });

//         // Cleanup function to disconnect and remove event listeners
//         return () => {
//              socket.current.disconnect();
//              socket.current.off('user_status');
//              socket.current.off('new_message');
//              socket.current.off('typing');
//              socket.current.off('stop_typing');
//         };
//     }, [queryParams, selectedUser, messageDispatch]);

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (content === '') {
//             setError('Please enter a message');
//             return;
//         }
//         if (!selectedUser) {
//             setError("Recipient is required");
//             return;
//         }
//         if (socketRef.current) {
//             socketRef.current.emit('new_message', {
//                 content,
//                 from: user.username,
//                 to: selectedUser.username,
//             });
//         }
//         try {
//             const message = { from: user.username, to: selectedUser.username, content };
//             if (socketRef.current) {
//                 socketRef.current.send(JSON.stringify(message));
//             }
//             setContent("");
//             setError("");
//             axios.post(`${baseUrl}/api/messages`, message)
//                 .then((res) => {
//                     setMessages((prevMessages) => [...prevMessages, res.data]);
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                     setError("Failed to send message");
//                 });
//         } catch (error) {
//             console.log(error);
//             setError("Failed to send message");
//         }
//     };


//     const handleUserClick = (user) => {
//         setSelectedUser(user);
//         setError('');
//     };

//     useEffect(() => {
//         getUsers()
//             .then((data) => {
//                 setUsers(data);
//             })
//             .catch((error) => console.log(error));
//     }, []);

//     useEffect(() => {
//         if (selectedUser) {
//             axios
//                 .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
//                 .then((response) => {
//                     setMessages(response.data);
//                 })
//                 .catch((error) => console.log(error));
//         }
//     }, [selectedUser,headers]);

//     useEffect(() => {
//         const newMessages = messages?.filter(
//             (message) => message?.to === user.username || message?.from === user.username
//         );
//         const otherUsers = newMessages?.map((message) =>
//             message.to === user.username ? message.from : message.to
//         );
//         const formattedMessages = newMessages?.reduce((acc, message) => {
//             const messageDate = new Date(message.createdAt);
//             const formattedDate = messageDate.toLocaleString('en-US', {
//                 hour: 'numeric',
//                 minute: 'numeric',
//                 hour12: true,
//             });

//             if (!acc[message.from]) {
//                 acc[message.from] = [];
//             }

//             acc[message.from].push({
//                 ...message,
//                 formattedDate,
//             });

//             return acc;
//         }, {});

//         setFormattedMessages(formattedMessages);

//         if (selectedUser && !otherUsers?.includes(selectedUser.username)) {
//             setSelectedUser(null);
//         }
//     }, [messages]);

//     const getUsers = async () => {
//         try {
//             const response = await axios.get(`${baseUrl}/api/users`, { headers });
//             return response.data;
//         } catch (error) {
//             throw error;
//         }
//     };
//     const handleTyping = () => {
//         if (socketRef.current) {
//             socketRef.current.emit('typing', selectedUser?.id);
//         }
//     };

//     const handleStopTyping = () => {
//         if (socketRef.current) {
//             socketRef.current.emit('stop_typing', selectedUser?.id);
//         }
//     };

// // console.log(formattedMessages)
//     return (
//         <div className="chat">
//             <Row className="chat-container">
//                 <Col xs={4} className="user-list">
//                     <ListGroup>
//                         {/* Display list of users */}
//                         {users.map((user) => (
//                             <ListGroup.Item
//                                 key={user.id}
//                                 action
//                                 onClick={() => handleUserClick(user)}
//                                 className={classNames({ active: selectedUser && selectedUser.id === user.id })}
//                             >
//                                 <span>{user.username} Status: {isOnline ? 'Online' : 'Offline'}</span>
//                             </ListGroup.Item>
//                         ))}
//                     </ListGroup>
//                 </Col>
//                 <Col xs={8} className="message-section">
//                     <div className="message-list" ref={uiMessagesRef}>
//                         {/* Display list of messages */}
//                         {formattedMessages[selectedUser?.username]?.map((message) => (
//                             <Message key={message.id} message={message} />
//                         ))}
//                         {/* {formattedMessages[0]?.message?.content} */}
//                     </div>
//                     <div className="message-input">
//                         {/* Input field for typing message */}
//                         <InputGroup>
//                             <FormControl
//                                 placeholder="Type your message..."
//                                 value={content}
//                                 onChange={(e) => setContent(e.target.value)}
//                                 onKeyDown={handleTyping}
//                                 onKeyUp={handleStopTyping}
//                             />
//                             <InputGroup.Append>
//                                 <Button type="submit" variant="primary" onClick={handleSubmit}>
//                                     Send
//                                 </Button>
//                             </InputGroup.Append>
//                         </InputGroup>
//                     </div>
//                 </Col>
//             </Row>
//         </div>
//     );
// };

// export default Chat;
