import React, { useEffect, Fragment, useState, useRef } from "react";
import "./topbar.css";
import { Col, Form } from "react-bootstrap";
import { Search, Person, Chat, Notifications } from "@material-ui/icons";
import { AuthContext, baseUrl } from "./../../App";
import { BrowserRouter, useHistory, Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import MenuBurger from "../menuBurger/MenuBurger";
import SearchBar from "./../searchBar";
import { useAuthState } from "../../context/auth";

export default function Topbar({ users, messages }) {
  const [selectedUser, setSelectedUser] = useState(null);
  // const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const token = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  // Access the length of messages and users count
  const messageCount = messages.length;
  console.log(messages)
  const usersCount = users?.length;
  console.log(messages);
  // useEffect(() => {
  //   // fetch list of users from backend
  //   fetch(`${baseUrl}/api/users?search=${searchQuery}`, headers)
  //     .then((response) => response.json())
  //     .then((data) => setUsers(data))
  //     .catch((error) => console.log(error));
  // }, [searchQuery]);
  const url = `${baseUrl}/images/`;
  // const [error, setError] = useState(null);
  // eslint-disable-next-line
  //const [isLoaded, setIsLoaded] = useState(false);
  let history = useHistory();
  // const { state } = React.useContext(AuthContext);
  const { user } = useAuthState();
  const storage = JSON.parse(localStorage.getItem("user"));
  // const token = "Bearer " + JSON.parse(localStorage.getItem("token"));
  // eslint-disable-next-line
  //const [data, setData] = useState("");
  // eslint-disable-next-line
  //const { user } = useContext(AuthContext);
  //let id = useParams();
  // const  isAdmin = storage.isAmin;
  // async function getUserData() {
  //   const URL = `${"/users/"}${id}`;
  //   const data = await fetch(URL, {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   });
  //   const response = await data.json();
  //   setData(response);
  //   console.log(response);
  //   setIsLoaded(true);
  //   setError(error);
  // }
  // useEffect(() => {
  //   getUserData();
  // }, []);
  return (
    <BrowserRouter>
      <div className="topbarContainer">
        {user?.username && (
          // <div>
          //   <div className="topbarContainer">
          //     <div className="topbarLeft">
          //       <h1>G</h1>
          //       <img
          //         className="topbarIco"
          //         src="/assets/icon/icon-left-font-monochrome-black.png"
          //         alt="icon"
          //         onClick={() => {
          //           history.push("/");
          //         }}
          //       />
          //       <div className="searchbar"></div>
          //     </div>
          //     <div className="topbarCenter"></div>{" "}
          //     <div className="topbarIcons">
          //       <div className="topbarIcon">
          //         <HomeIcon
          //           onClick={() => {
          //             history.push("/");
          //           }}
          //         />
          //       </div>
          //       {/* <div className="topbarIconItem">
          //         <SupervisorAccountIcon
          //           onClick={() => {
          //             history.push("/admin/" + storage.id);
          //           }}
          //         />
          //       </div> */}
          //       <div className="topbarIconItem"></div>
          //     </div>{" "}
          //     <div className="topbarIcons">
          //       {/* <div className="topbarIconItem">
          //         <img
          //           src={
          //             state.user.profilePicture
          //               ? url + state.user.profilePicture
          //               : "/assets/personnoAvatar.png"
          //           }
          //           alt=""
          //           className="topbarImg"
          //           onClick={() => {
          //             history.push("/profile/" + state.user.id);
          //           }}
          //         />
          //         <span className="topbarLinks">{state.user.username} </span> */}
          //     </div>
          //     <div className="topbarIconItem">
          //       <MenuBurger />
          //     </div>
          //   </div>
          // </div>
          // // </div>
          <div className="topbarContainer">
            <div className="topbarLeft">
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  history.push("/");
                }}
              >
                <span className="logo">Kifach</span>
              </Link>
            </div>
            <div className="top-bar">
              {/* <span>{`Messages: ${messageCount}`}</span>
              <span>{`Users: ${usersCount}`}</span> */}
              {/* Rest of the component code */}
            </div>
            {/* <div className="topbarCenter">
              <SearchBar setSelectedUser={setSelectedUser} />
            </div> */}
            <div className="topbarRight">
              <div className="topbarLinks">
                <div className="topbarIconItem">
                  <SupervisorAccountIcon
                    onClick={() => {
                      history.push("/admin/" + user.id);
                    }}
                  />
                  <span className="topbarIconBadge">{usersCount}</span>
                </div>
              </div>
              {/* <span
                className="logo"
                onClick={() => {
                  history.push("/messages");
                }}
              >
                Ms
              </span>
              <span
                className="logo"
                onClick={() => {
                  history.push("/chat");
                }}
              >
                Cht
              </span> */}
              <div className="topbarIcons">
                <div className="topbarIconItem"></div>
                {/* <div className="topbarIconItem">
                  <Person />
                  <span className="topbarIconBadge">1</span>
                </div> */}
                <div className="topbarIconItem">
                  <Chat
                    onClick={() => {
                      history.push("/messenger");
                    }}
                  />
                  <span className="topbarIconBadge">{messageCount}</span>
                </div>
                {/* <div className="topbarIconItem">
                  <Notifications />
                  <span className="topbarIconBadge">1</span>
                </div> */}
              </div>
              <img
                src={
                  user.profilePicture
                    ? url + user.profilePicture
                    : url + "noAvatar.png"
                }
                alt=""
                className="topbarImg"
                onClick={() => {
                  history.push("/profile/" + user.username);
                }}
              />
              <span className="topbarLinks">{user.username} </span>
            </div>
            <div className="topbarIconItem">
              <MenuBurger />
            </div>
          </div>
        )}
      </div>
    </BrowserRouter>
  );
}
