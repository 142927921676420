
// import { io } from "socket.io-client";
// import React, { useEffect, Fragment, useState, useRef } from "react";
// import { Col, Form } from "react-bootstrap";
// import ListGroup from "react-bootstrap/ListGroup";
// import Alert from "react-bootstrap/Alert";
// import Badge from "react-bootstrap/Badge";
// import FormControl from "react-bootstrap/FormControl";
// import InputGroup from "react-bootstrap/InputGroup";
// import Button from "react-bootstrap/Button";
// import Message from "./Message";
// import axios from "axios";
// // import { baseUrl } from './../../App';
// import { PropagateLoader } from 'react-spinners';
// import { useAuthDispatch, useAuthState } from "../../context/auth";
// import { useMessageDispatch } from "../../context/message";
// import Spinner from './../../components/spinner/Spinner';
// import notificationSound from "./../../assets/notif.wav";
// import AudioCall from './audioCall';
// import VideoCall from './videoCall';
// import { baseUrl } from "../../App";
// import "./messages.css";
// // const nodeEnv = process.env.NODE_ENV;
// // let ioUrl;

// // if (nodeEnv === 'production') {
// //   ioUrl = process.env.REACT_APP_IO_URL || 'wss://ba.up.railway.app';
// // } else {
// //   ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
// // }

// // export const baseUrl = ioUrl;
// function Messages({ history }) {
//   const [loading, setLoading] = useState(true);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const authDispatch = useAuthDispatch();
//   const messageDispatch = useMessageDispatch();

//   const [users, setUsers] = useState([]);
//   const [messages, setMessages] = useState([]);
//   const [formattedMessages, setFormattedMessages] = useState([]);

//   const { user } = useAuthState();
//   const [content, setContent] = useState("");
//   const [error, setError] = useState("");
//   const socketRef = useRef(null);
//   //audio call
//   const [callActive, setCallActive] = useState(false);
//   const [participants, setParticipants] = useState([]);
//   const token = JSON.parse(localStorage.getItem("token"));
//   const queryParams = { userId: 2 };

//   const headers = {
//     "Accept": "application/json",
//     "Authorization": `Bearer ${token}`
//   };
//   const host = "ws://localhost:4000";
//   socketRef.current = io(host, {
//     path: "/messages",
//     transports: ['websocket'],  // https://stackoverflow.com/a/52180905/8987128
//     upgrade: false,
//     query: queryParams,
//     reconnection: false,
//     rejectUnauthorized: false
//   });

//   socketRef.current.once("connect", () => {

//     // USER IS ONLINE
//     socketRef.current.on("online", (userId) => {
//       console.log(userId, "Is Online!"); // update online status
//     });

//     // USER IS OFFLINE
//     socketRef.current.on("offline", (userId) => {
//       console.log(userId, "Is Offline!"); // update offline status
//     });

//   });
//   useEffect(() => {
//     socketRef.current = io("ws://localhost:4000");

//     socketRef.current.on("callRequest", ({ participants }) => {
//       // Handle incoming call request
//       setParticipants(participants);
//       setCallActive(true);
//     });

//     return () => {
//       if (socketRef.current) {
//         socketRef.current.disconnect();
//       }
//     };
//   }, []);

//   const startCall = () => {
//     // Send a call request to the server
//     socketRef.current.emit("startCall", { participants });
//     setCallActive(true);
//   };

//   const endCall = () => {
//     // Send a call end signal to the server
//     socketRef.current.emit("endCall");
//     setCallActive(false);
//   };
//   /*/*/
//   useEffect(() => {
//     const userId = user.id;
//     // Create the socket instance if it doesn't exist
//     if (!socketRef.current) {
//       socketRef.current = io("ws://localhost:4000");

//       socketRef.current.emit("login", { userId: user.id });
//       // Send a message to the server when connected
//       socketRef.current.on("connection", () => {
//         socketRef.current.emit("sendMessage", "connect Hello Server!");
//         socketRef.current.emit("login", { userId: user.id });
//       });

//       // Receive messages from the server
//       socketRef.current.on("message", (message) => {
//         console.log("Received socket.on message from server:", message);
//         setMessages((prevMessages) => [...prevMessages, message]);
//       });
//     }

//     // Clean up the socket connection on component unmount
//     return () => {
//       if (socketRef.current) {
//         socketRef.current.disconnect();
//         socketRef.current = null;
//       }
//     };
//   }, []);
//   useEffect(() => {
//     getUsers()
//       .then((data) => {
//         setUsers(data);
//       })
//       .catch((error) => console.log(error));
//   }, []);

//   const NEW_MESSAGE = {};

//   // here we are aliasing the data and error variables
//   const { data: messageData, error: messageError } = NEW_MESSAGE;

//   useEffect(() => {
//     if (messageError) console.log(messageError);

//     if (messageData) {
//       const message = messageData.newMessage;
//       const otherUser =
//         user.username === message.to ? message.from : message.to;

//       messageDispatch({
//         type: "ADD_MESSAGE",
//         payload: {
//           username: otherUser,
//           message,
//         },
//       });
//     }
//   }, [messageError, messageData, user.username, messageDispatch]);

//   useEffect(() => {
//     const newMessages = messages?.filter(
//       (message) => message.to === user.username || message.from === user.username
//     );
//     const otherUsers = newMessages?.map((message) =>
//       message.to === user.username ? message.from : message.to
//     );
//     const uniqueOtherUsers = [...new Set(otherUsers)];

//     const formattedMessages = uniqueOtherUsers?.map((username) => {
//       const messagesWithUser = newMessages.filter(
//         (message) => message.to === username || message.from === username
//       );
//       const lastMessage =
//         messagesWithUser[messagesWithUser.length - 1]?.content;
//       const formattedMessage = {
//         username,
//         message: {
//           content: lastMessage,
//           createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
//         },
//       };


//       return formattedMessage;
//     });

//     // Sort the formatted messages by the latest message createdAt timestamp
//     const sortedMessages = formattedMessages.sort((a, b) => {
//       const aTimestamp = a.message.createdAt || 0;
//       const bTimestamp = b.message.createdAt || 0;
//       return bTimestamp - aTimestamp;
//     });
//     setFormattedMessages(sortedMessages);
//   }, [user, messages]);

//   const logout = () => {
//     authDispatch({ type: "LOGOUT" });
//     window.location.href = "/login";
//   };

//   const getUsers = async () => {
//     try {
//       const response = await axios.get(`${baseUrl}/api/users/`, { headers });
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   };



//   // Create a new Audio object with the notification sound file
//   const notificationAudio = new Audio(notificationSound);
//   useEffect(() => {
//     if (selectedUser) {
//       axios
//         .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
//         .then((response) => {
//           setMessages(response.data);

//         })
//         .catch((error) => console.log(error));
//     }
//   }, [selectedUser]);


//   const submitMessage = async (e) => {
//     e.preventDefault();
//     if (!selectedUser) {
//       setError("Recipient is required");
//       return;
//     }

//     if (content.trim() === "") {
//       setError("Message content is required");
//       return;
//     }

//     try {
//       const message = { from: user.username, to: selectedUser.username, content };
//       setContent("");
//       setError("");

//       const res = await axios.post(`${baseUrl}/api/messages`, message);


//       // You can also update the UI optimistically by adding the message to the local state
//       setMessages((prevMessages) => [...prevMessages, { message: res.data.content, id: res.data.id }])

//       console.log(res.data.content)
//       socketRef.current.emit("sendMessage", message)
//       notificationAudio.play();
//     } catch (error) {
//       console.log(error);
//       setError("Failed to send message");
//     }
//   };


//   let selectedChatMarkup;

//   if (!messages) {
//     selectedChatMarkup = <p className="info-text">MESSAGESSelect a friend</p>;
//   } else if (messages.length === 0) {
//     selectedChatMarkup = <p className="info-text">You are now connected! Send your first message</p>;
//   } else {
//     selectedChatMarkup = messages.slice().reverse().map((message, index) => (
//       <Fragment key={message.id}>
//         <Message message={message} />
//         {index !== messages.length - 1 && (
//           <div className="invisible">
//             <hr className="m-0" />
//           </div>
//         )}
//       </Fragment>
//     ));

//   }


//   return (
//     <div className="contain">
//       <AudioCall />
//       {/*  */}

//       <Col sm={3}>
//         {users.filter((x) => x.username !== "Admin").length === 0 && (
//           <Alert variant="info">No User Found</Alert>
//         )}
//         <ListGroup>
//           <h5 className="mb-3">  {user.username}: Your Friends  </h5>
//           {users
//             .filter((x) => x.username !== "Admin")
//             .map((user) => (
//               <ListGroup.Item
//                 action
//                 key={user.username}
//                 variant={user.username === selectedUser?.username ? "info" : ""}
//                 // onClick={() => selectedUser(user)}
//                 onClick={() => setSelectedUser(user)}
//               >
//                 <Badge
//                   bg={
//                     selectedUser?.username === user.username
//                       ? user.online
//                         ? "primary"
//                         : "secondary"
//                       : user.unread
//                         ? "danger"
//                         : user.online
//                           ? "primary"
//                           : "secondary"
//                   }
//                 >
//                   {selectedUser?.username === user.username
//                     ? user.online
//                       ? "Online"
//                       : "Offline"
//                     : user.unread
//                       ? "New"
//                       : user.online
//                         ? "Online"
//                         : "Offline"}
//                 </Badge>
//                 &nbsp;
//                 :.:{user.username}
//                 {formattedMessages[0]?.username === user.username ? (
//                   <>
//                     <Badge className="content">{formattedMessages[0]?.message.content}</Badge>
//                   </>
//                 ) : (
//                   <Badge className="no-message">Select a friend</Badge>
//                 )}

//               </ListGroup.Item>

//             ))}

//         </ListGroup>
//       </Col>
//       <Col xs={0} md={8}>
//         <div>
//           {loading ? (
//             <div className="spinner-container">
//               <Spinner color="#123abc" />
//             </div>
//           ) : (
//             <div>
//               {selectedChatMarkup}
//             </div>
//           )}

//         </div>
//         <div className="messages-box chat-area d-flex flex-column-reverse">
//           {selectedUser ? (
//             selectedChatMarkup
//           ) : (
//             <p className="info-text">Select a friend</p>
//           )}
//         </div>
//         <div>
//           <Form onSubmit={submitMessage}>
//             <Form.Group className="my-3 d-flex align-items-center">
//               <Form.Control
//                 type="text"
//                 className="message-input p-2 rounded-pill bg-secondary border-0"
//                 placeholder="type a message ..."
//                 value={content}
//                 onChange={(e) => setContent(e.target.value)}

//               />
//               <i
//                 className="fas fa-paper-plane fa-2x text-primary mx-2"
//                 onClick={submitMessage}
//                 role="button"
//               ></i>
//             </Form.Group>
//           </Form>
//         </div>
//         <div>
//           {callActive ? (
//             <div>
//               <h3>Call in progress</h3>
//               <ul>
//                 {participants.map((participant) => (
//                   <li key={participant.id}>{participant.name}</li>
//                 ))}
//               </ul>
//               <button onClick={endCall}>End Call</button>
//             </div>
//           ) : (
//             <div>
//               <h3>Start a Call</h3>
//               <button onClick={startCall}>Start Call</button>
//             </div>
//           )}
//         </div>
//       </Col>
//     </div>
//   );
// }

// export default Messages;
import React, { useState, useEffect, useRef, Fragment } from "react";
import { ListGroup, Badge, Spinner, Alert, Form, Col } from "react-bootstrap";
import { io } from "socket.io-client";
import axios from "axios";
import { useAuthState } from "../../context/auth";
import { useMessageDispatch, useMessageState } from "../../context/message";
import Message from "./Message";
import notificationSound from "./../../assets/notif.wav";
import { baseUrl } from "../../App";
import Users from "../Home/Users";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const messageDispatch = useMessageDispatch();
  // const { messages } = useMessageState();
  const [users, setUsers] = useState([]);

  const { user } = useAuthState();
  const token = (localStorage.getItem("token"));

  // const baseUrl = "http://localhost:4000";

  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = io(baseUrl, {
      auth: {
        token: `Bearer ${token}`,
      },
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to socket server");
    });

    socketRef.current.on("messageReceived", (message) => {
      messageDispatch({ type: "ADD_MESSAGE", payload: message });

      // Play notification sound
      const audio = new Audio(notificationSound);
      audio.play();
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket error:", error);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [messageDispatch, token]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/users`, {
          headers,
        });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const [content, setContent] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);

    if (content.trim() === "") {
      setError(true);
      return;
    }

    const message = {
      from: user.id,
      to: selectedUser.id,
      content: content.trim(),
    };
    const res = await axios.post(`${baseUrl}/api/messages`, message);
    setMessages((prevMessages) => [...prevMessages, { message: res.data.content, id: res.data.id }])
    socketRef.current.emit("sendMessage", message);

    messageDispatch({ type: "ADD_MESSAGE", payload: message });
    setContent("");
  };

  // const formattedMessages = messages?.filter(
  //   (message) =>
  //     (message.from === user.id && message.to === selectedUser?.id) ||
  //     (message.from === selectedUser?.id && message.to === user.id)
  // );
  const [formattedMessages, setFormattedMessages] = useState([]);
  const [formattedMessage, setFormattedMessage] = useState({});
  useEffect(() => {
    const newMessages = messages.filter(
      (message) =>
        message.to === user.username || message.from === user.username
    );
    const otherUsers = newMessages.map((message) =>
      message.to === user.username ? message.from : message.to
    );
    const uniqueOtherUsers = [...new Set(otherUsers)];

    const formattedMessages = uniqueOtherUsers.map((username) => {
      const messagesWithUser = newMessages.filter(
        (message) => message.to === username || message.from === username
      );
      const lastMessage =
        messagesWithUser[messagesWithUser.length - 1]?.content;
      const formattedMessage = {
        username,
        message: {
          content: lastMessage,
          createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
        },
      };

      return formattedMessage;
    });

    setFormattedMessages(formattedMessages);
  }, [user, messages]);

  formattedMessages.forEach((formattedMessage) => {
    console.log(formattedMessage?.message.content);
  });
  useEffect(() => {
    if (selectedUser) {
      axios
        .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
        .then((response) => {
          setMessages(response.data);

        })
        .catch((error) => console.log(error));
    }
  }, [selectedUser]);


  return (
    <div className="d-flex">
      <div style={{ width: "250px" }}>

        < h4 > Users 2</h4>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <ListGroup>
            {users.map((user) => (
              <ListGroup.Item
                key={user.id}
                action
                onClick={() => handleSelectUser(user)}
                active={selectedUser?.id === user.id}
              >
                <Badge
                  bg={
                    selectedUser?.username === user.username
                      ? user.online
                        ? "primary"
                        : "secondary"
                      : user.unread
                        ? "danger"
                        : user.online
                          ? "primary"
                          : "secondary"
                  }
                >
                  {selectedUser?.username === user.username
                    ? user.online
                      ? "Online"
                      : "Offline"
                    : user.unread
                      ? "New"
                      : user.online
                        ? "Online"
                        : "Offline"}
                </Badge>
                {user.username}
                {formattedMessages[0]?.username === user.username ? (
                  <Badge pill variant="danger" className="ml-2">
                    {formattedMessages[0]?.message.content}
                  </Badge>
                ) : (<Badge>Select a un Esclave </Badge>)}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
      <div className="flex-grow-1">
        {selectedUser ? (
          <div className="d-flex flex-column h-100">
            <div className="flex-grow-1 overflow-auto">
              {messages?.map((message, index) => (
                <Fragment key={index}>
                  <Message message={message} />
                  {index === formattedMessages.length - 1 && loading && (
                    <Spinner
                      animation="border"
                      variant="primary"
                      style={{ marginLeft: "50%", marginTop: "1rem" }}
                    />
                  )}
                </Fragment>
              ))}
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Col>
                  <Form.Control
                    type="text"
                    className="rounded-pill"
                    placeholder="Enter your message..."
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    isInvalid={error}
                  />
                </Col>
                <Col xs="auto">
                  <button className="btn btn-primary rounded-pill" type="submit">
                    Send
                  </button>
                  <i
                    className="fas fa-paper-plane fa-2x text-primary mx-2"
                    // onClick={submitMessage}
                    role="button"
                  ></i>
                </Col>
              </Form.Row>
            </Form>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center">
              <Alert variant="info">Select a user to start messaging</Alert>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
